import "./Kontakt.css"
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { TextField, Grid, Button, Checkbox, FormControlLabel, Typography, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { apiaddress } from "../const.js"
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    TextField: {
    }
}));

export default function Main(props) {
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [buttonState, setButtonState] = useState("");
    const theme = useTheme();

    const [vorname, setVorname] = useState("");
    const [vornameError, setVornameError] = useState(false);
    const [nachname, setNachname] = useState("");
    const [nachnameError, setNachnameError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [nachricht, setNachricht] = useState("");
    const [nachrichtError, setNachrichtError] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [captchaError, setCaptchaError] = useState(false);

    const [country, setCountry] = useState("");

    function sendEmail() {
        if (!verify())
            return;

        setButtonState("loading")

        axios.post(`${apiaddress}/sendEmail/`, { params: { vorname: vorname, nachname: nachname, email: email, nachricht: nachricht } })
            .then(response => {
                setButtonState("finished");
            })
            .catch((err) => {
                alert("Fehler bei Datenübertragung! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    function refreshCaptcha() {
        loadCaptchaEnginge(5, "white", theme.palette.primary.main);
    }

    function verify() {
        if (country !== "")
            return false;

        const vornameError = vorname === ""
        const nachnameError = nachname === ""
        const nachrichtError = nachricht === ""
        const emailError = !new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$").test(email)
        setVornameError(vornameError);
        setNachnameError(nachnameError);
        setNachrichtError(nachrichtError);
        setEmailError(emailError);
        const fieldVerify = !(vornameError || nachnameError || emailError || nachrichtError);
        const captchaVerify = validateCaptcha(captcha, false)

        setCaptchaError(!captchaVerify)
        setCaptcha("");
        refreshCaptcha();

        return (fieldVerify && captchaVerify)
    }

    useEffect(() => {
        refreshCaptcha();
    }, [])

    let buttonComponent;
    if (buttonState === "loading")
        buttonComponent =
            (
                <CircularProgress />
            )
    else if (buttonState === "finished")
        buttonComponent =
            (
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ height: 40, width: 100, margin: 20, pointerEvents: "none", boxShadow: "none" }} >
                    <CheckIcon style={{ color: "white" }} />
                </Button>
            )
    else
        buttonComponent =
            (
                <Button
                    type="submit"
                    variant="contained"
                    color="inherit"
                    disabled={!agreementChecked}
                    style={{ height: 40, width: 100, margin: 20 }}
                    onClick={sendEmail}>
                    Senden
                </Button>
            )

    return (
        <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item container direction='column' alignItems='left' style={{ width: "550px" }}>
                <TextField id='forename' fullWidth label="Vorname" onChange={(event) => setVorname(event.target.value)} error={vornameError} required placeholder="z.B. Max" variant="standard" margin='normal' />
                <TextField id='lastname' fullWidth label="Nachname" onChange={(event) => setNachname(event.target.value)} error={nachnameError} required placeholder='z.B. Mustermann' variant="standard" margin='normal' />
                <TextField id='email' fullWidth label="Email" onChange={(event) => setEmail(event.target.value)} error={emailError} required placeholder='z.B. max@mustermann.de' variant="standard" margin='normal' />
                <div id='country'><TextField fullWidth label="Country" onChange={(event) => setCountry(event.target.value)} required placeholder='z.B. max@mustermann.de' variant="standard" margin='normal' /></div>
                <TextField id="message" fullWidth label="Nachricht" onChange={(event) => setNachricht(event.target.value)} error={nachrichtError} multiline rows={4} required variant="filled" margin='normal' />
                <Grid container direction="row" justifyContent='left' alignItems='center'>
                    <TextField style={{ width: "150px" }} label="Captcha" value={captcha} onChange={(event) => setCaptcha(event.target.value)} error={captchaError} required variant="standard" margin='dense' />
                    <div style={{ marginTop: 10 }}><LoadCanvasTemplateNoReload /></div>
                </Grid>

                <Grid height={10} />
                <FormControlLabel control={<Checkbox checked={agreementChecked} onChange={(event) => setAgreementChecked(event.target.checked)} />} label={<Typography sx={{ fontSize: 13 }} >Ich bin damit einverstanden, dass meine Daten zur Bearbeitung meiner Anfrage elektronisch erhoben und gespeichert werden. <br /> Ich habe die Datenschutzrichtlinien gelesen und akzeptiere diese ausdrücklich.</Typography>} />
            </Grid>
            <CheckIcon style={{ color: "white" }} />
            {buttonComponent}
        </Grid>
    );
}