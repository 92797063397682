import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import SaveIcon from '@mui/icons-material/Save';
import { useKeycloak } from '@react-keycloak/web'
import { Breadcrumbs, TextField, Grid, Button, Typography, Tooltip } from '@mui/material';
import { apiaddress } from "../const"

const useStyles = makeStyles(() => ({
    toolbar: {
        height: '80px'
    },
    maingrid: {
        height: '100%',
        width: '100%',
        minWidth: "600px"
    },
    TextField: {
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const classes = useStyles();
    const goLastPage = props.goLastPage;
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true)

    const [name, setName] = useState("");
    const [disziplin, setDisziplin] = useState("");
    const [verband, setVerband] = useState("");
    const [liga, setLiga] = useState("");
    const [leiter, setLeiter] = useState("");
    const [kontakt1, setKontakt1] = useState("");
    const [kontakt2, setKontakt2] = useState("");

    function handleReturn() {
        setMainwindow("search");
    }

    function handleBackArrow() {
        setMainwindow("");
    }

    useEffect(() => {
        if (name !== "" && disziplin !== "" && verband !== "" && liga !== "" && leiter !== "")
            setDownloadButtonDisabled(false);
        else
            setDownloadButtonDisabled(true);
    }, [name, disziplin, verband, liga, leiter, kontakt1, kontakt2])

    function handleCreate(event) {
        const inputsObject = function () {
            let object = {};
            object["Name"] = name;
            object["Disziplin"] = disziplin;
            object["Verband"] = verband;
            object["Liga"] = liga;
            object["Rundenkampfleiter"] = leiter;
            object["Kontakt_1"] = kontakt1;
            object["Kontakt_2"] = kontakt2;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.post(`${apiaddress}/rundenkaempfe/`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .then(response => {
                        setMainwindow("search");
                    })
                    .catch((err) => {
                        alert("Fehler beim Erstellen des Rundenkampfs! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })

    }

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" className={classes.toolbar} style={{ backgroundColor: theme.palette.primary.main, minWidth: "600px" }}>
                <Grid item container justifyContent="space-between" alignItems='center' style={{ minWidth: "600px" }}>
                    <Grid style={{ paddingLeft: 17 }} >
                        <Breadcrumbs>
                            <Button
                                className="breadcrumbs-button"
                                variant="text"
                                size='small'
                                color='secondary'
                                onClick={handleBackArrow}
                            >
                                Startseite
                            </Button>
                            <Button
                                className="breadcrumbs-button"
                                variant="text"
                                size='small'
                                color='secondary'
                                onClick={handleReturn}
                            >
                                Rundenkämpfe
                            </Button>
                            <Typography ></Typography>

                        </Breadcrumbs>
                        <Typography variant="h4">Rundenkampf anlegen</Typography>
                    </Grid>
                    <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                        <Tooltip title="Rundenkampf speichern" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                            <Button
                                type="submit"
                                variant="contained"
                                color="inherit"
                                onClick={handleCreate}
                                disabled={downloadButtonDisabled}
                                style={{ margin: 5 }}>
                                <SaveIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                            <Button
                                type="submit"
                                variant="contained"
                                color="inherit"
                                onClick={goLastPage}
                                style={{ margin: 5 }}>
                                <ReturnIcon />
                            </Button></Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container style={{ paddingLeft: "20px", paddingBottom: "15px" }}>
                <Grid item container md={6}>
                    <Grid item container direction='column' style={{ marginRight: "20px" }}>
                        <TextField className={classes.TextField} id="standard-basic" label="Name" required placeholder="z.B. Herbstrundenkämpfe" variant="standard" margin='dense' value={name} onChange={(event) => { setName(event.target.value) }} />
                        <TextField className={classes.TextField} id="standard-basic" label="Disziplin" required placeholder='z.B. 1.10 Luftgewehr' variant="standard" margin='dense' value={disziplin} onChange={(event) => { setDisziplin(event.target.value) }} />
                        <TextField className={classes.TextField} id="standard-basic" label="Verband" required placeholder='z.B. Schützenkreis KL-DO' variant="standard" margin='dense' value={verband} onChange={(event) => { setVerband(event.target.value) }} />
                        <TextField className={classes.TextField} id="standard-basic" label="Liga" required placeholder='z.B. Kreisliga' variant="standard" margin='dense' value={liga} onChange={(event) => { setLiga(event.target.value) }} />
                    </Grid>
                </Grid>
                <Grid item container md={6}>
                    <Grid item container direction='column' style={{ marginRight: "20px" }}>
                        <TextField className={classes.TextField} id="standard-basic" label="Rundenkampfleiter" required placeholder='z.B. Max Mustermann' variant="standard" margin='dense' value={leiter} onChange={(event) => { setLeiter(event.target.value) }} />
                        <TextField className={classes.TextField} id="standard-basic" label="Kontakt 1" placeholder='z.B. Tel. 0160 1234567' variant="standard" margin='dense' value={kontakt1} onChange={(event) => { setKontakt1(event.target.value) }} />
                        <TextField className={classes.TextField} id="standard-basic" label="Kontakt 2" placeholder='z.B. Mail muster@mann.de' variant="standard" margin='dense' value={kontakt2} onChange={(event) => { setKontakt2(event.target.value) }} />
                    </Grid></Grid>
            </Grid>
        </Grid>
    );
}