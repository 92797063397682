import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { Typography, Grid, Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    TextField: {
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const classes = useStyles();
    const clickFunc = props.onClick;
    const year = new Date().getFullYear();

    function handleFooterClick(event) {
        if (clickFunc)
            clickFunc(event);
    }

    function handleImpressumClick(event) {
        window.location.href = "https://ssk-kl.de/impressum/";
    }

    return (
        <Grid item container justifyContent="space-between" className={classes.footer} style={{ alignItems: "center", background: `${theme.palette.secondary.main}`, paddingTop: "2px" }}>
            <Grid item style={{ marginLeft: "15px", marginBottom: "3px" }}>
                <Typography variant='button' style={{ fontSize: "12px", color: "white" }}>{`Copyright ${year} - RUNDENKAMPF.DE`}</Typography>
            </Grid>
            <Grid item container xs style={{ justifyContent: "flex-end", minWidth: "360px", marginRight: "10px" }} >
                <Button type="submit" variant="text" onClick={handleFooterClick} style={{ fontSize: "12px", color: "white" }} >Kontakt</Button>
                <Button type="submit" variant="text" onClick={handleFooterClick} style={{ fontSize: "12px", color: "white" }} >Datenschutz</Button>
                <Button type="submit" variant="text" onClick={handleImpressumClick} style={{ fontSize: "12px", color: "white" }} >Impressum</Button>
            </Grid>
        </Grid>
    );
}