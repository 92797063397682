import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import logobig from "../img/logobig.png";
import usericon from "../img/usericon.png";
import { Typography, Grid, Button, Divider, Avatar } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    TextField: {
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const classes = useStyles();
    const keycloak = props.keycloak;

    function accountManagement() {
        if (keycloak)
            keycloak.accountManagement();
    }

    function logout() {
        if (keycloak)
            keycloak.logout();
    }

    function login() {
        if (keycloak)
        keycloak.login()
    }

    let textComponent;
    if (keycloak.authenticated)
        textComponent = (
            <>
                <Typography variant='button' style={{ marginRight: "10px", color: theme.palette.secondary.main, fontSize: "18px" }}>{`${keycloak?.tokenParsed?.preferred_username !== undefined ? `Hallo ${keycloak?.tokenParsed?.preferred_username}!` : ""}`}</Typography>
                <Divider orientation="vertical" style={{ height: "23px" }} />
                <Button
                    color="secondary"
                    type="submit"
                    variant="text"
                    onClick={accountManagement}
                    style={{ fontSize: "18px" }}
                >
                    Konto
                </Button>
                <Divider orientation="vertical" style={{ height: "23px" }} />
                <Button
                    type="submit"
                    color="secondary"
                    variant="text"
                    onClick={logout}
                    style={{ fontSize: "18px" }}
                >
                    Logout
                </Button>
            </>
        );
        else
        textComponent = (
            <>
            <Button
                    type="submit"
                    color="secondary"
                    variant="text"
                    onClick={login}
                    style={{ fontSize: "18px" }}
                >
                    Login
                </Button>
            </>
        )

    let loginPage;
    if (keycloak !== undefined)
        loginPage = (
            <Grid container item style={{ justifyContent: "flex-end", alignItems: "center", marginTop: "7px", marginRight: "10px" }}>
                <Avatar src={usericon} sx={{ width: 28, height: 28, marginRight: "8px", marginBottom: "4px" }}></Avatar>
                {textComponent}
            </Grid>
        )
    else
        loginPage = "";

    return (
        <Grid item container justifyContent="space-between" className={classes.header} wrap="nowrap">
            <img className="header-image" src={logobig} alt="" style={{ marginLeft: "-28px", marginTop: "-30px", height: `145px` }} />
            <Grid item container xs direction="row" style={{ justifyContent: "flex-end", alignItems: "baseline", minWidth: "270px" }}>
                {loginPage}
            </Grid>
        </Grid>
    );
}