import "../app.css";
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { useKeycloak } from '@react-keycloak/web'
import { makeStyles, useTheme } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useCSVReader, useCSVDownloader } from 'react-papaparse';
import AG_GRID_LOCALE_DE from './AG_Grid_Locale_De.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Breadcrumbs, Button, Grid, Tooltip, Typography } from '@mui/material';
import { apiaddress } from "../const"

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    datagrid: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const ID = props.id;
    const name = props.name;
    const { CSVReader } = useCSVReader();
    const { CSVDownloader, Type } = useCSVDownloader();
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const [tabledata, setTableData] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loadedSchuetzen, setLoadedSchuetzen] = useState({});
    const editSchuetzenID = props.editSchuetzenID;
    const addSchuetze = props.addSchuetze
    const classes = useStyles();
    const goLastPage = props.goLastPage;

    useEffect(() => {
        updateTable();
    }, [])

    async function updateTable() {
        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/schuetzen`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: ID } })
            .then(response => {
                let newTableData = [];
                response.data.forEach(value => {
                    newTableData.push(value)
                });
                if (newTableData.length > 0)
                    setTableData(newTableData);
            })
            .catch((err) => {
                if (err.response.status === 401)
                    alert("Authorisierungsfehler bei Datenabruf! Loggen Sie sich bitte erneut ein!")
                else if (err.response.status === 403)
                    alert("Authorisierungsfehler bei Datenabruf! Sie sind nicht berechtigt, diese Inhalte einzusehen!")
                else if (err.response.status !== 404)
                    alert("Fehler bei Datenabruf! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    function cellSelected(event) {
        editSchuetzenID(event.data.id, event.data.name, loadedSchuetzen);
    }

    function handleBackArrow(event) {
        setMainwindow("");
    }

    function handleRundenkaempfe(event) {
        setMainwindow("search");
    }

    function onGridReady(params) {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    }

    function resizeGrid() {
        const allColumnIds = [];
        if (gridApi && columnApi) {
            columnApi.getAllGridColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            columnApi.autoSizeColumns(allColumnIds);
            gridApi.sizeColumnsToFit();
        }
    }

    function handleReturn() {
        setMainwindow("edit");
    }

    function handleCSV(results) {
        let promises = [];
        results.data.forEach((object) => {
            if (object.Name !== undefined && object.Mannschaft !== undefined && object.Mannschaftsfuehrer !== undefined && object.Kontakt_1 !== undefined && object.Kontakt_2 !== undefined) {
                promises.push(makeSchuetze(object));
            }
        })
        Promise.allSettled(promises)
            .then(() => updateTable())
            .catch(() => alert("Fehler bei CSV Import!"))
    }

    function makeSchuetze(object) {
        return new Promise((resolve, reject) => {
            object["Rundenkampfnummer"] = ID;
            object["Mannschaftsfuehrer"] = String(object["Mannschaftsfuehrer"]).toLowerCase() === "ja" || String(object["Mannschaftsfuehrer"]) === "1" ? true : false;
            if (object["Mannschaftsfuehrer"] === true) {
                if (loadedSchuetzen[object["Mannschaft"]] === true)
                    object["Mannschaftsfuehrer"] = false;
                else {
                    let newLoadedSchuetzen = loadedSchuetzen;
                    newLoadedSchuetzen[object["Mannschaft"]] = true
                    setLoadedSchuetzen(newLoadedSchuetzen);
                }
            }

            keycloak.updateToken(10)
                .then(() => {
                    axios.post(`${apiaddress}/schuetzen/`, object, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                        .then(response => {
                            resolve();
                        })
                        .catch((err) => {
                            reject();
                        })
                })
                .catch(() => {
                    alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                    window.location.reload();
                    reject();
                })
        })
    }

    useEffect(() => {
        setColumns([
            { field: 'id', headerName: 'Nummer', hide: true },
            { field: 'name', headerName: 'Name', filter: "agTextColumnFilter", minWidth: 150 },
            { field: 'mannschaft', headerName: 'Mannschaft', filter: "agTextColumnFilter", minWidth: 170 },
            {
                field: 'mannschaftsfuehrer', headerName: 'Mannschaftsführer', filter: "agTextColumnFilter", minWidth: 210,
                valueFormatter: (value) => {
                    value = value.value;
                    if (value === 1)
                        return "Ja"
                    else
                        return "Nein";
                }
            },
            { field: 'kontakt_1', headerName: 'Kontakt 1', filter: "agTextColumnFilter", minWidth: 150 },
            { field: 'kontakt_2', headerName: 'Kontakt 2', filter: "agTextColumnFilter", minWidth: 150, flex: 1, suppressSizeToFit: false }
        ]);

        setRows(tabledata.map((value, index, array) => {
            if (value !== undefined) {
                try {
                    const id = value.Nummer;
                    const name = value.Name;
                    const mannschaft = value.Mannschaft;
                    const mannschaftsfuehrer = value.Mannschaftsfuehrer;
                    const kontakt_1 = value.Kontakt_1;
                    const kontakt_2 = value.Kontakt_2;
                    if (id && name && mannschaft && mannschaftsfuehrer !== undefined) {
                        return { "id": id, 'name': name, "mannschaft": mannschaft, "mannschaftsfuehrer": mannschaftsfuehrer, "kontakt_1": kontakt_1, "kontakt_2": kontakt_2 };
                    }
                } catch (error) {
                }
            }
        }));
    }, [tabledata])

    useEffect(() => {
        setLoadedSchuetzen(() => {
            let newLoadedSchuetzen = {};
            rows.forEach((value) => {
                if (value.mannschaftsfuehrer === true || value.mannschaftsfuehrer === 1) {
                    newLoadedSchuetzen[value.mannschaft] = true;
                }
            });
            return newLoadedSchuetzen;
        });
    }, [rows])

    function prepareData() {
        let array = [];
        rows.forEach((value) => {
            let object = {};
            object.Name = value.name;
            object.Mannschaft = value.mannschaft;
            object.Mannschaftsfuehrer = value.mannschaftsfuehrer;
            object.Kontakt_1 = value.kontakt_1;
            object.Kontakt_2 = value.kontakt_2;
            array.push(object);
        })
        if (array.length === 0) {
            array.push({ Name: "", Mannschaft: "", Mannschaftsfuehrer: "", Kontakt_1: "", Kontakt_2: "" })
        }
        return array;
    }

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar} style={{ backgroundColor: theme.palette.primary.main }}>
                <Grid style={{ paddingLeft: 17 }} >
                    <Breadcrumbs>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleBackArrow}
                        >
                            Startseite
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleRundenkaempfe}
                        >
                            Rundenkämpfe
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleReturn}
                        >
                            {name}
                        </Button>
                        <Typography ></Typography>
                    </Breadcrumbs>
                    <Typography variant="h4">Schützen</Typography>
                </Grid>
                <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                    <CSVReader
                        onUploadAccepted={(results) => handleCSV(results)}
                        config={{ header: true }}
                    >
                        {({
                            getRootProps
                        }) => (
                            <>
                                <Tooltip title="CSV importieren" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                                    <Button
                                        type="submit"
                                        {...getRootProps()}
                                        variant="contained"
                                        color="inherit"
                                        style={{ margin: 5 }}>
                                        <UploadIcon />
                                    </Button></Tooltip>
                            </>
                        )}
                    </CSVReader>
                    <CSVDownloader
                        type={Type.Link}
                        filename={`${name}_Schuetzen`}
                        bom={true}
                        config={{
                            delimiter: ';',
                        }}
                        data={prepareData}
                    >
                        <Tooltip title="Als CSV exportieren" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                            <Button
                                type="submit"
                                variant="contained"
                                color="inherit"
                                style={{ margin: 5 }}>
                                <DownloadIcon />
                            </Button></Tooltip>
                    </CSVDownloader>
                    <Tooltip title="Schützen hinzufügen" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            onClick={() => { addSchuetze(loadedSchuetzen); }}
                            style={{ margin: 5 }}>
                            <AddIcon />
                        </Button></Tooltip>
                    <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            onClick={goLastPage}
                            style={{ margin: 5 }}>
                            <ReturnIcon />
                        </Button></Tooltip>
                </Grid>
            </Grid>
            <Grid item container xs>
                <div className="ag-theme-material" style={{ height: '100%', width: '100%', minHeight: "300px" }} >
                    <AgGridReact localeText={AG_GRID_LOCALE_DE} defaultColDef={{ sortable: true, resizable: true, suppressColumnVirtualisation: true, suppressSizeToFit: true }} rowData={rows} columnDefs={columns} rowSelection="single" pagination={true} paginationPageSize={50} onCellDoubleClicked={cellSelected} onGridReady={onGridReady} onFirstDataRendered={resizeGrid}>
                    </AgGridReact>
                </div>
            </Grid>
        </Grid>
    );
}