import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useKeycloak } from '@react-keycloak/web'
import { AgGridReact } from 'ag-grid-react';
import AG_GRID_LOCALE_DE from './AG_Grid_Locale_De.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Breadcrumbs, Typography, Button, Grid, Checkbox, Tooltip, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { apiaddress } from "../const"

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    datagrid: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));

export default function Main(props) {
    const ID = props.id;
    const name = props.name;
    const theme = useTheme();
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const [tabledata, setTableData] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [zeigenBisWK, setZeigenBisWK] = useState(updateZeigenBisWK);
    const goLastPage = props.goLastPage;
    const classes = useStyles();

    useEffect(() => {
        updateTable();
    }, [])

    async function updateTable() {
        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/schuetzen/${ID}/ergebnisse`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
            .then(response => {
                let newTableData = [];
                response.data.forEach(value => {
                    newTableData.push(value)
                });
                if (newTableData.length > 0)
                    setTableData(newTableData);
            })
            .catch((err) => {
                if (err.response.status === 401)
                    alert("Authorisierungsfehler bei Datenabruf! Loggen Sie sich bitte erneut ein!")
                else if (err.response.status === 403)
                    alert("Authorisierungsfehler bei Datenabruf! Sie sind nicht berechtigt, diese Inhalte einzusehen!")
                else if (err.response.status !== 404)
                    alert("Fehler bei Datenabruf! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    async function updateZeigenBisWK() {
        await keycloak.updateToken(5);
        axios.get(`${apiaddress}/rundenkaempfedata/`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: ID } })
            .then(response => {
                setZeigenBisWK(response.data.ZeigenBisWK);
            })
            .catch((err) => {
                if (err.response.status === 401)
                    alert("Authorisierungsfehler bei Datenabruf! Loggen Sie sich bitte erneut ein!")
                else if (err.response.status === 403)
                    alert("Authorisierungsfehler bei Datenabruf! Sie sind nicht berechtigt, diese Inhalte einzusehen!")
                else if (err.response.status !== 404)
                    alert("Fehler bei Datenabruf! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    function cellEdited(event) {
        const inputsObject = function () {
            let object = {};
            object["Nummer"] = event.data.id;
            object["WK1"] = event.data.wk1 === "" ? null : event.data.wk1;
            object["WK1EW"] = event.data.wk1ew;
            object["WK2"] = event.data.wk2 === "" ? null : event.data.wk2;
            object["WK2EW"] = event.data.wk2ew;
            object["WK3"] = event.data.wk3 === "" ? null : event.data.wk3;
            object["WK3EW"] = event.data.wk3ew;
            object["WK4"] = event.data.wk4 === "" ? null : event.data.wk4;
            object["WK4EW"] = event.data.wk4ew;
            object["WK5"] = event.data.wk5 === "" ? null : event.data.wk5;
            object["WK5EW"] = event.data.wk5ew;
            object["WK6"] = event.data.wk6 === "" ? null : event.data.wk6;
            object["WK6EW"] = event.data.wk6ew;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.put(`${apiaddress}/schuetzen/ergebnisse`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .catch((err) => {
                        alert("Fehler beim Eintragen vom Ergebniss! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    function onZeigenBisWkChanged(zeigenBisWK) {
        const inputsObject = function () {
            let object = {};
            object["Rundenkampfnummer"] = ID;
            object["ZeigenBisWK"] = zeigenBisWK;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.put(`${apiaddress}/rundenkaempfe/`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .catch((err) => {
                        alert("Fehler beim Bearbeiten des Rundenkampfs! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    function handleBackArrow(event) {
        setMainwindow("");
    }

    function handleRundenkaempfe(event) {
        setMainwindow("search");
    }

    function onGridReady(params) {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    }

    function resizeGrid() {
        const allColumnIds = [];
        if (gridApi && columnApi) {
            columnApi.getAllGridColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            columnApi.autoSizeColumns(allColumnIds);
            gridApi.sizeColumnsToFit();
        }
    }

    function handleReturn() {
        setMainwindow("edit");
    }

    function gridValueFormatter(params) {
        const value = String(params.value);
        if (value === "NaN" || value === "")
            return "";
        else
            return Number(value).toFixed(2).replace(".", ",").replace(",00", "");
    }

    function handleCheckbox(params, checked) {
        params.node.setDataValue(params.column.colId + "ew", checked);
    }

    useEffect(() => {
        setColumns([
            { field: 'id', headerName: 'Nummer', hide: true },
            { field: 'name', headerName: 'Name', filter: "agTextColumnFilter", minWidth: 150, },
            { field: 'mannschaft', headerName: 'Mannschaft', filter: "agTextColumnFilter", minWidth: 170, },
            {
                field: 'wk1', headerName: 'WK1 (EW)', filter: "agNumberColumnFilter", minWidth: 130, editable: true,
                valueParser: params => {
                    const newValue = String(params.newValue);
                    if (isNaN(newValue.replace(",", ".")))
                        return params.oldValue
                    else
                        return newValue
                },
                numberParser: text => {
                    const value = String(text);
                    return value === null ? null : Number(value.replace(",", "."))
                },
                valueGetter: params => {
                    let value = String(params.data.wk1);
                    value = value.replace(",", ".");
                    return value === "" ? value : Number(value);
                },
                cellRenderer: params => { const value = gridValueFormatter(params); return <Grid item container><Checkbox onChange={(event) => handleCheckbox(params, event.target.checked)} defaultChecked={params.data.wk1ew} />{value}</Grid> }
            },
            { field: 'wk1ew', hide: true },
            {
                field: 'wk2', headerName: 'WK2 (EW)', filter: "agNumberColumnFilter", minWidth: 130, editable: true,
                valueParser: params => {
                    const newValue = String(params.newValue);
                    if (isNaN(newValue.replace(",", ".")))
                        return params.oldValue;
                    else
                        return (newValue.replace(".", ","));
                },
                numberParser: text => {
                    const value = String(text);
                    return value === null ? null : Number(value.replace(",", "."))
                },
                valueGetter: params => {
                    let value = String(params.data.wk2);
                    value = value.replace(",", ".");
                    return value === "" ? value : Number(value);
                },
                cellRenderer: params => { const value = gridValueFormatter(params); return <Grid item container><Checkbox onChange={(event) => handleCheckbox(params, event.target.checked)} defaultChecked={params.data.wk2ew} />{value}</Grid> }
            },
            { field: 'wk2ew', hide: true },
            {
                field: 'wk3', headerName: 'WK3 (EW)', filter: "agNumberColumnFilter", minWidth: 130, editable: true,
                valueParser: params => {
                    const newValue = String(params.newValue);
                    if (isNaN(newValue.replace(",", ".")))
                        return params.oldValue;
                    else
                        return (newValue.replace(".", ","));
                },
                numberParser: text => {
                    const value = String(text);
                    return value === null ? null : Number(value.replace(",", "."))
                },
                valueGetter: params => {
                    let value = String(params.data.wk3);
                    value = value.replace(",", ".");
                    return value === "" ? value : Number(value);
                },
                cellRenderer: params => { const value = gridValueFormatter(params); return <Grid item container><Checkbox onChange={(event) => handleCheckbox(params, event.target.checked)} defaultChecked={params.data.wk3ew} />{value}</Grid> }
            },
            { field: 'wk3ew', hide: true },
            {
                field: 'wk4', headerName: 'WK4 (EW)', filter: "agNumberColumnFilter", minWidth: 130, editable: true,
                valueParser: params => {
                    const newValue = String(params.newValue);
                    if (isNaN(newValue.replace(",", ".")))
                        return params.oldValue;
                    else
                        return (newValue.replace(".", ","));
                },
                numberParser: text => {
                    const value = String(text);
                    return value === null ? null : Number(value.replace(",", "."))
                },
                valueGetter: params => {
                    let value = String(params.data.wk4);
                    value = value.replace(",", ".");
                    return value === "" ? value : Number(value);
                },
                cellRenderer: params => { const value = gridValueFormatter(params); return <Grid item container><Checkbox onChange={(event) => handleCheckbox(params, event.target.checked)} defaultChecked={params.data.wk4ew} />{value}</Grid> }
            },
            { field: 'wk4ew', hide: true },
            {
                field: 'wk5', headerName: 'WK5 (EW)', filter: "agNumberColumnFilter", minWidth: 130, editable: true,
                valueParser: params => {
                    const newValue = String(params.newValue);
                    if (isNaN(newValue.replace(",", ".")))
                        return params.oldValue;
                    else
                        return (newValue.replace(".", ","));
                },
                numberParser: text => {
                    const value = String(text);
                    return value === null ? null : Number(value.replace(",", "."))
                },
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    let value = String(params.data.wk5);
                    value = value.replace(",", ".");
                    return value === "" ? value : Number(value);
                },
                cellRenderer: params => { const value = gridValueFormatter(params); return <Grid item container><Checkbox onChange={(event) => handleCheckbox(params, event.target.checked)} defaultChecked={params.data.wk5ew} />{value}</Grid> }
            },
            { field: 'wk5ew', hide: true },
            {
                field: 'wk6', headerName: 'WK6 (EW)', filter: "agNumberColumnFilter", minWidth: 130, flex: 1, editable: true, suppressSizeToFit: false,
                valueParser: params => {
                    const newValue = String(params.newValue);
                    if (isNaN(newValue.replace(",", ".")))
                        return params.oldValue;
                    else
                        return (newValue.replace(".", ","));
                },
                numberParser: text => {
                    const value = String(text);
                    return value === null ? null : Number(value.replace(",", "."))
                },
                valueGetter: params => {
                    let value = String(params.data.wk6);
                    value = value.replace(",", ".");
                    return value === "" ? value : Number(value);
                },
                cellRenderer: params => { const value = gridValueFormatter(params); return <Grid item container><Checkbox onChange={(event) => handleCheckbox(params, event.target.checked)} defaultChecked={params.data.wk6ew} />{value}</Grid> }
            },
            { field: 'wk6ew', hide: true },
        ]);

        setRows(tabledata.map((value, index, array) => {
            if (value !== undefined) {
                try {
                    const id = value.Nummer;
                    const name = value.Name;
                    const mannschaft = value.Mannschaft;
                    const wk1 = value.WK1;
                    const wk1ew = value.WK1EW === 1 || value.WK1EW === true ? true : false;
                    const wk2 = value.WK2;
                    const wk2ew = value.WK2EW === 1 || value.WK2EW === true ? true : false;
                    const wk3 = value.WK3;
                    const wk3ew = value.WK3EW === 1 || value.WK3EW === true ? true : false;
                    const wk4 = value.WK4;
                    const wk4ew = value.WK4EW === 1 || value.WK4EW === true ? true : false;
                    const wk5 = value.WK5;
                    const wk5ew = value.WK5EW === 1 || value.WK5EW === true ? true : false;
                    const wk6 = value.WK6;
                    const wk6ew = value.WK6EW === 1 || value.WK6EW === true ? true : false;
                    if (id && name && mannschaft) {
                        return { "id": id, 'name': name, "mannschaft": mannschaft, "wk1": wk1, "wk1ew": wk1ew, "wk2": wk2, "wk2ew": wk2ew, "wk3": wk3, "wk3ew": wk3ew, "wk4": wk4, "wk4ew": wk4ew, "wk5": wk5, "wk5ew": wk5ew, "wk6": wk6, "wk6ew": wk6ew };
                    }
                } catch (error) {
                }
            }
        }));
    }, [tabledata])

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar} style={{ backgroundColor: theme.palette.primary.main }}>
                <Grid style={{ paddingLeft: 17 }} >
                    <Breadcrumbs>
                        <Button
                            variant="text"
                            color='secondary'
                            size='small'
                            onClick={handleBackArrow}
                        >
                            Startseite
                        </Button>
                        <Button
                            variant="text"
                            color='secondary'
                            size='small'
                            onClick={handleRundenkaempfe}
                        >
                            Rundenkämpfe
                        </Button>
                        <Button
                            variant="text"
                            color='secondary'
                            size='small'
                            onClick={handleReturn}
                        >
                            {name}
                        </Button>
                        <Typography ></Typography>
                    </Breadcrumbs>
                    <Typography variant="h4">Ergebnisse</Typography>
                </Grid>
                <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15, alignItems: "center" }}>
                    <Grid>
                        <Tooltip title="Rundenkampf Ergebnisse veröffentlichen bis..." arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                            <FormControl id="FormControl" variant="standard" style={{ minWidth: 160 }}>
                                <InputLabel style={{ marginBottom: "10px", paddingBottom: "10px" }}>
                                    <Typography style={{ letterSpacing: "0.02857em", color: "#333333", textTransform: "uppercase", fontWeight: 500}}>VERÖFFENTLICHEN BIS...</Typography>
                                </InputLabel>
                                <Select onChange={(event) => { setZeigenBisWK(event.target.value); onZeigenBisWkChanged(event.target.value) }} value={zeigenBisWK} style={{ height: "43px", fontSize: "1.7rem"}}>
                                    <MenuItem value={1}>WK1</MenuItem>
                                    <MenuItem value={2}>WK2</MenuItem>
                                    <MenuItem value={3}>WK3</MenuItem>
                                    <MenuItem value={4}>WK4</MenuItem>
                                    <MenuItem value={5}>WK5</MenuItem>
                                    <MenuItem value={6}>WK6</MenuItem>
                                </Select>
                            </FormControl></Tooltip>
                    </Grid>
                    <Grid item marginLeft={3}>
                        <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                            <Button
                                type="submit"
                                variant="contained"
                                color="inherit"
                                onClick={goLastPage}
                                style={{ margin: 5 }}>
                                <ReturnIcon />
                            </Button></Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs>
                <div className="ag-theme-material" style={{ height: '100%', width: '100%', minHeight: "300px" }} >
                    <AgGridReact localeText={AG_GRID_LOCALE_DE} defaultColDef={{ sortable: true, resizable: true, suppressColumnVirtualisation: true, suppressSizeToFit: true }} rowData={rows} columnDefs={columns} rowSelection="none" suppressRowClickSelection={true} stopEditingWhenCellsLoseFocus={true} pagination={true} paginationPageSize={50} onCellValueChanged={cellEdited} onGridReady={onGridReady} onModelUpdated={resizeGrid} onFirstDataRendered={resizeGrid}>
                    </AgGridReact>
                </div>
            </Grid>
        </Grid>
    );
}