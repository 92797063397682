import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import SaveIcon from '@mui/icons-material/Save';
import { useKeycloak } from '@react-keycloak/web'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Breadcrumbs, Tooltip, TextField, Button, Grid, Typography } from '@mui/material';
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import { apiaddress } from "../const"

const useStyles = makeStyles(() => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    TextField: {
    }
}));

export default function Main(props) {
    const ID = props.id;
    const rundenkampfName = props.name;
    const theme = useTheme();
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const classes = useStyles();
    const goLastPage = props.goLastPage;
    const loadedSchuetzen = props.loadedSchuetzen;
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true);

    const [name, setName] = useState("");
    const [mannschaft, setMannschaft] = useState("");
    const [mannschaftsfuehrer, setMannschaftsfuehrer] = useState(false);
    const [kontakt1, setKontakt1] = useState("");
    const [kontakt2, setKontakt2] = useState("");

    function handleBackArrow(event) {
        setMainwindow("");
    }

    function handleReturn() {
        setMainwindow("edit");
    }

    function handleRundenkaempfe(event) {
        setMainwindow("search");
    }

    function handleSchuetzenTablePage() {
        setMainwindow("searchSchuetzen");
    }

    function checkFuehrerDisabled() {
        return loadedSchuetzen[String(mannschaft).trim()] === true
    }

    useEffect(() => {
        if (name !== "" && mannschaft !== "")
            setDownloadButtonDisabled(false);
        else
            setDownloadButtonDisabled(true);
    }, [name, mannschaft, kontakt1, kontakt2])

    function handleCreate(event) {
        const inputsObject = function () {
            let object = {};
            object["Rundenkampfnummer"] = ID;
            object["Name"] = name;
            object["Mannschaft"] = mannschaft;
            object["Mannschaftsfuehrer"] = mannschaftsfuehrer;
            object["Kontakt_1"] = kontakt1;
            object["Kontakt_2"] = kontakt2;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.post(`${apiaddress}/schuetzen/`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .then(response => {
                        setMainwindow("searchSchuetzen");
                    })
                    .catch((err) => {
                        alert("Fehler beim Erstellen des Schützen! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })

    }

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar} style={{ backgroundColor: theme.palette.primary.main }}>
                <Grid style={{ paddingLeft: 17 }} >
                    <Breadcrumbs>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleBackArrow}
                        >
                            Startseite
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleRundenkaempfe}
                        >
                            Rundenkämpfe
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleReturn}
                        >
                            {rundenkampfName}
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleSchuetzenTablePage}
                        >
                            Schützen
                        </Button>
                        <Typography ></Typography>
                    </Breadcrumbs>
                    <Typography variant="h4">Schütze anlegen</Typography>
                </Grid>
                <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                    <Tooltip title="Schütze speichern" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            onClick={handleCreate}
                            disabled={downloadButtonDisabled}
                            style={{ margin: 5 }}>
                            <SaveIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            onClick={goLastPage}
                            style={{ margin: 5 }}>
                            <ReturnIcon />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item container direction='column' style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "15px" }}>
                <TextField className={classes.TextField} id="standard-basic" label="Name" required placeholder="z.B. Mustermann, Max" variant="standard" margin='dense' value={name} onChange={(event) => { setName(event.target.value) }} />
                <TextField className={classes.TextField} id="standard-basic" label="Mannschaft" required placeholder='z.B. SG Musterstadt II' variant="standard" margin='dense' value={mannschaft} onChange={(event) => { setMannschaft(event.target.value) }} />
                <TextField className={classes.TextField} id="standard-basic" label="Kontakt 1" placeholder='z.B. Tel. 01234 - 567890' variant="standard" margin='dense' value={kontakt1} onChange={(event) => { setKontakt1(event.target.value) }} />
                <TextField className={classes.TextField} id="standard-basic" label="Kontakt 2" placeholder='z.B. Mail max@mustermann.de' variant="standard" margin='dense' value={kontakt2} onChange={(event) => { setKontakt2(event.target.value) }} />
                <Grid style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <FormGroup aria-label="position" row>
                        <Tooltip title="Ein Mannschaftsführer wurde schon eingetragen!" arrow placement="right" disabled={true} disableInteractive={true} disableTouchListener={true} disableFocusListener={true} disableHoverListener={!checkFuehrerDisabled()}>
                            <FormControlLabel
                                control={<Checkbox onChange={(event) => { setMannschaftsfuehrer(event.target.checked); }} />}
                                label="Mannschaftsführer?"
                                labelPlacement="end"
                                disabled={checkFuehrerDisabled()}
                            />
                        </Tooltip>
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
    );
}