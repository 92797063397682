import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import { CardActionArea, CardContent, CardMedia, Card, Breadcrumbs, Tooltip } from '@mui/material';
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import DeleteIcon from '@mui/icons-material/Delete';
import { useKeycloak } from '@react-keycloak/web'
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from "../img/search.png";
import TableIcon from "../img/table.png";
import { TextField, Typography, Grid, Button } from '@mui/material';
import { apiaddress } from "../const"

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    TextField: {
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const classes = useStyles();
    const [ID, setID] = useState(props.id);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [editDisabled, setEditDisabled] = useState(true)
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const goLastPage = props.goLastPage;

    const [name, setName] = useState("");
    const [disziplin, setDisziplin] = useState("");
    const [verband, setVerband] = useState("");
    const [liga, setLiga] = useState("");
    const [leiter, setLeiter] = useState("");
    const [kontakt1, setKontakt1] = useState("");
    const [kontakt2, setKontakt2] = useState("");

    useEffect(() => {
        loadData();
    }, [ID])

    useEffect(() => {
        if (!editDisabled) {
            if (name === "" || disziplin === "" || verband === "" || liga === "" || leiter === "")
                setSaveDisabled(true);
            else
                setSaveDisabled(false);
        }
    });

    function handleBackArrow(event) {
        setMainwindow("");
    }

    function handleReturn() {
        setMainwindow("search");
    }

    function editClick(event) {
        if (editDisabled)
            setEditDisabled(false);
        else
            updateData();
    }

    function handleCreateSchuetze() {
        setMainwindow("searchSchuetzen");
    }

    function handleErgebnisseButton() {
        setMainwindow("ergebnisse");
    }

    function checkDisabled() {
        return saveDisabled || !hasLoaded;
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function updateData() {
        const inputsObject = function () {
            let object = {};
            object["Rundenkampfnummer"] = ID;
            object["Name"] = name;
            object["Disziplin"] = disziplin;
            object["Verband"] = verband;
            object["Liga"] = liga;
            object["Rundenkampfleiter"] = leiter;
            object["Kontakt_1"] = kontakt1;
            object["Kontakt_2"] = kontakt2;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.put(`${apiaddress}/rundenkaempfe/`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .then(response => {
                        setEditDisabled(true);
                    })
                    .catch((err) => {
                        alert("Fehler beim Bearbeiten des Rundenkampfs! Bitte wenden Sie sich an einen Administrator!")
                        setEditDisabled(true);
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    function loadData() {
        keycloak.updateToken(10)
            .then(() => {
                axios.get(`${apiaddress}/rundenkaempfe/`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: ID } })
                    .then(response => {
                        const object = response.data[0];
                        if (!object || String(object.Rundenkampfnummer) !== String(ID)) {
                            alert("Rundenkampf konnte nicht gefunden werden!");
                            return;
                        }
                        setName(object.Name);
                        setDisziplin(object.Disziplin);
                        setVerband(object.Verband);
                        setLiga(object.Liga);
                        setLeiter(object.Rundenkampfleiter);
                        setKontakt1(object.Kontakt_1);
                        setKontakt2(object.Kontakt_2);
                        setHasLoaded(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Fehler bei Rundenkampf Datenabruf! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    function handleDelete() {
        if (deleteDisabled) {
            setDeleteDisabled(false);
            return;
        }

        keycloak.updateToken(10)
            .then(() => {
                axios.delete(`${apiaddress}/rundenkaempfe/`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: ID } })
                    .then(response => {
                        handleReturn();
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Fehler bei Rundenkampf Löschung! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    async function createSchuetze(schuetze) {
        keycloak.updateToken(10)
            .then(() => {
                axios.post(`${apiaddress}/schuetzen/`, schuetze, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .then(response => {
                        return Promise.resolve();
                    })
                    .catch((err) => {
                        alert("Fehler beim Erstellen des Schützen! Bitte wenden Sie sich an einen Administrator!")
                        return Promise.reject();
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })

    }

    function handleCopy() {
        const inputsObject = function () {
            let object = {};
            object["Name"] = name + " Kopie";
            object["Disziplin"] = disziplin;
            object["Verband"] = verband;
            object["Liga"] = liga;
            object["Rundenkampfleiter"] = leiter;
            object["Kontakt_1"] = kontakt1;
            object["Kontakt_2"] = kontakt2;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.post(`${apiaddress}/rundenkaempfe/`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .then(response => {
                        axios.get(`${apiaddress}/schuetzen`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: ID } })
                            .then(schuetzenresponse => {
                                let promises = [];
                                schuetzenresponse.data.forEach(value => {
                                    value.Rundenkampfnummer = response.data;
                                    promises.push(createSchuetze(value));
                                });

                                Promise.all(promises)
                                    .then(() => setID(response.data))
                                    .catch(() => {
                                        alert("Fehler beim Kopieren der Schützen des Rundenkampfs! Bitte wenden Sie sich an einen Administrator!")
                                        setID(response.data);
                                    })
                            })
                            .catch((err) => {
                                if (err.response.status === 401)
                                    alert("Authorisierungsfehler bei Datenabruf! Loggen Sie sich bitte erneut ein!")
                                else if (err.response.status === 403)
                                    alert("Authorisierungsfehler bei Datenabruf! Sie sind nicht berechtigt, diese Inhalte einzusehen!")
                                else if (err.response.status !== 404)
                                    alert("Fehler bei Datenabruf! Bitte wenden Sie sich an einen Administrator!")
                            })
                    })
                    .catch((err) => {
                        alert("Fehler beim Kopieren des Rundenkampfs! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ backgroundColor: theme.palette.primary.main }} className={classes.toolbar}>
                <Grid style={{ paddingLeft: 17 }} >
                    <Breadcrumbs>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleBackArrow}
                        >
                            Startseite
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleReturn}
                        >
                            Rundenkämpfe
                        </Button>
                        <Typography ></Typography>
                    </Breadcrumbs>
                    <Typography variant="h4">{name}</Typography>
                </Grid>
                <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                    <Tooltip title="Rundenkampf löschen" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color={deleteDisabled ? "inherit" : "error"}
                            disabled={!editDisabled || !hasLoaded}
                            onClick={handleDelete}
                            style={{ margin: 5 }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Rundenkampf kopieren" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            disabled={!editDisabled || !hasLoaded}
                            onClick={handleCopy}
                            style={{ margin: 5 }}>
                            <ContentCopyIcon />
                        </Button></Tooltip>
                    <Tooltip title="Rundekampf bearbeiten" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            disabled={checkDisabled()}
                            onClick={editClick}
                            style={{ margin: 5 }}>
                            {editDisabled ? <EditIcon /> : <SaveIcon />}
                        </Button></Tooltip>
                    <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            onClick={goLastPage}
                            style={{ margin: 5 }}>
                            <ReturnIcon />
                        </Button></Tooltip>
                </Grid>
            </Grid>
            <Grid item container direction='row' style={{ paddingLeft: "20px", paddingBottom: "15px" }}>
                <Grid item container md={6}>
                    <Grid item container direction='column' style={{ marginRight: "20px" }}>
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Name" required size='small' placeholder="Herbstrundenkämpfe" variant="standard" margin='dense' value={name} onChange={(event) => { setName(event.target.value) }} />
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Disziplin" required size='small' placeholder='1.10 Luftgewehr' variant="standard" margin='dense' value={disziplin} onChange={(event) => { setDisziplin(event.target.value) }} />
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Verband" required size='small' placeholder='Schützenkreis KL-DO' variant="standard" margin='dense' value={verband} onChange={(event) => { setVerband(event.target.value) }} />
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Liga" required size='small' placeholder='Kreisliga' variant="standard" margin='dense' value={liga} onChange={(event) => { setLiga(event.target.value) }} />
                    </Grid></Grid>
                <Grid item container md={6}>
                    <Grid item container direction='column' style={{ marginRight: "20px" }}>
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Rundenkampfleiter" required size='small' placeholder='Max Mustermann' variant="standard" margin='dense' value={leiter} onChange={(event) => { setLeiter(event.target.value) }} />
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Kontakt 1" placeholder='Tel. 0160 1234567' variant="standard" size='small' margin='dense' value={kontakt1} onChange={(event) => { setKontakt1(event.target.value) }} />
                        <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Kontakt 2" placeholder='Mail muster@mann.de' variant="standard" size='small' margin='dense' value={kontakt2} onChange={(event) => { setKontakt2(event.target.value) }} />
                    </Grid></Grid>
                <Grid item container style={{ marginTop: "20px" }}>
                    <Grid item container direction="row" >
                        <Card id="schützen" variant='outlined' style={{ borderColor: "transparent", borderTopLeftRadius: "5%", borderTopRightRadius: "5%", width: "160px" }} >
                            <CardActionArea onClick={() => editDisabled && hasLoaded ? handleCreateSchuetze() : ""}>
                                <CardMedia
                                    component="img"
                                    image={SearchIcon}
                                    alt="Schützen"
                                    style={{ padding: "10px" }}
                                />
                                <CardContent>
                                    <Typography variant='h6' color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>{`Schützen`}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card id="ergebnisse" variant='outlined' style={{ borderColor: "transparent", borderTopLeftRadius: "5%", borderTopRightRadius: "5%", width: "160px" }} >
                            <CardActionArea onClick={() => editDisabled && hasLoaded ? handleErgebnisseButton() : ""} >
                                <CardMedia
                                    component="img"
                                    image={SearchIcon}
                                    alt="ergebnisse"
                                    style={{ padding: "10px" }}
                                />
                                <CardContent>
                                    <Typography variant='h6' color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>{`Ergebnisse`}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card id="tabelle" variant='outlined' style={{ borderColor: "transparent", borderTopLeftRadius: "5%", borderTopRightRadius: "5%", width: "160px" }} >
                            <CardActionArea onClick={() => editDisabled && hasLoaded ? openInNewTab(`${window.location}ergebnisse/${ID}`) : ""}>
                                <CardMedia
                                    component="img"
                                    image={TableIcon}
                                    alt="tabelle"
                                    style={{ padding: "10px" }}
                                />
                                <CardContent>
                                    <Typography variant='h6' color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>{`Tabelle`}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}