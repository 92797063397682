exports.formatDate = (date) => {
    if (!(date instanceof Date))
        date = this.dateFromString(date);
    date = String(date.toLocaleString('de-DE', { timeZone: "Europe/Berlin", year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }));
    if (date === "Invalid Date")
        return undefined;
    date = date.replace(" ", "");
    date = date.replace(",", ".");
    var dateElements = date.split(".");
    var newDate = dateElements[2] + "-" + dateElements[1] + "-" + dateElements[0] + " " + dateElements[3];
    return newDate;
}

exports.formatPrettyDate = (date) => {
    if (!(date instanceof Date))
        date = this.dateFromString(date);
    date = String(date.toLocaleString('de-DE', { timeZone: "Europe/Berlin", year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }));
    date = date.replace(",", ".").replace(" ", "");
    var dateElements = date.split(".");
    var newDate = `${dateElements[0]}.${dateElements[1]}.${dateElements[2]}, ${dateElements[3]} Uhr`
    return newDate;
}

exports.dateFromString = (string) => {
    var stringElements = String(string).split("-");
    var year = stringElements[0];
    var month = Number(stringElements[1]) - 1;
    stringElements = String(stringElements[2]).split(" ");
    var day = stringElements[0];
    stringElements = String(stringElements[1]).split(":");
    var hour = stringElements[0];
    var minute = stringElements[1];
    var second = stringElements[2];
    var date = new Date(year, month, day, hour, minute, second);
    return date;
}