import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useKeycloak } from '@react-keycloak/web'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { FormControlLabel, TextField, Typography, Grid, Button, Breadcrumbs, Tooltip } from '@mui/material';
import { apiaddress } from "../const"

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    TextField: {
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const ID = props.id;
    const rundenkampfName = props.name;
    const schuetzenID = props.schuetzenID;
    const schuetzenName = props.schuetzenName;
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const classes = useStyles();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [editDisabled, setEditDisabled] = useState(true)
    var loadedSchuetzen = props.loadedSchuetzen;
    const goLastPage = props.goLastPage;

    const [name, setName] = useState("");
    const [mannschaft, setMannschaft] = useState("");
    const [mannschaftsfuehrer, setMannschaftsfuehrer] = useState(false);
    const [kontakt1, setKontakt1] = useState("");
    const [kontakt2, setKontakt2] = useState("");

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (!editDisabled) {
            if (name === "" || mannschaft === "")
                setSaveDisabled(true);
            else
                setSaveDisabled(false);
        }
    });

    function handleBackArrow(event) {
        setMainwindow("");
    }

    function handleReturn() {
        setMainwindow("searchSchuetzen");
    }

    function handleRundenkampf() {
        setMainwindow("edit");
    }

    function handleSchuetzen() {
        setMainwindow("searchSchuetzen");
    }

    function checkDisabled() {
        return saveDisabled || !hasLoaded;
    }

    function editClick(event) {
        if (editDisabled)
            setEditDisabled(false);
        else
            updateData();
    }

    function checkMannschaftDisabled() {
        if (loadedSchuetzen[String(mannschaft).trim()] && mannschaftsfuehrer === true)
            setMannschaftsfuehrer(false);
        const isDisabled = editDisabled === true || loadedSchuetzen[mannschaft]
        return isDisabled;
    }

    function updateData(event) {
        const inputsObject = function () {
            let object = {};
            object["Rundenkampfnummer"] = ID;
            object["ID"] = schuetzenID
            object["Name"] = name;
            object["Mannschaft"] = mannschaft;
            object["Mannschaftsfuehrer"] = mannschaftsfuehrer;
            object["Kontakt_1"] = kontakt1;
            object["Kontakt_2"] = kontakt2;

            return object;
        }();

        keycloak.updateToken(10)
            .then(() => {
                axios.put(`${apiaddress}/schuetzen/`, inputsObject, { headers: { Authorization: `Bearer ${keycloak.token}` } })
                    .then(response => {
                        setEditDisabled(true);
                    })
                    .catch((err) => {
                        alert("Fehler beim Aktualisieren des Schützen! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })

    }

    function loadData() {
        keycloak.updateToken(10)
            .then(() => {
                axios.get(`${apiaddress}/schuetzen/`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: ID, schuetzenID: schuetzenID } })
                    .then(response => {
                        const object = response.data[0];
                        if (!object || object.Rundenkampfnummer !== ID) {
                            alert("Fehler bei Schützen Datenabruf! Bitte wenden Sie sich an einen Administrator!");
                            return;
                        }

                        setName(object.Name);
                        setMannschaft(object.Mannschaft);
                        setMannschaftsfuehrer(object.Mannschaftsfuehrer);
                        setKontakt1(object.Kontakt_1);
                        setKontakt2(object.Kontakt_2);
                        setHasLoaded(true);
                        if (loadedSchuetzen[object.Mannschaft] && object.Mannschaftsfuehrer)
                            loadedSchuetzen[object.Mannschaft] = false;
                    })
                    .catch((err) => {
                        alert("Fehler bei Schützen Datenabruf! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    function handleDelete() {
        if (deleteDisabled) {
            setDeleteDisabled(false);
            return;
        }

        keycloak.updateToken(10)
            .then(() => {
                axios.delete(`${apiaddress}/schuetzen/`, { headers: { Authorization: `Bearer ${keycloak.token}` }, params: { id: schuetzenID, rundenkampfnummer: ID } })
                    .then(response => {
                        handleReturn();
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Fehler bei Schützen Löschung! Bitte wenden Sie sich an einen Administrator!")
                    })
            })
            .catch(() => {
                alert("Login Sitzung ist abgelaufen! Bitte melden Sie sich erneut an!");
                window.location.reload();
            })
    }

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ backgroundColor: theme.palette.primary.main }} className={classes.toolbar}>
                <Grid style={{ paddingLeft: 17 }} >
                    <Breadcrumbs>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleBackArrow}
                        >
                            Startseite
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleReturn}
                        >
                            Rundenkämpfe
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleRundenkampf}
                        >
                            {rundenkampfName}
                        </Button>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleSchuetzen}
                        >
                            Schützen
                        </Button>
                        <Typography ></Typography>
                    </Breadcrumbs>
                    <Typography variant="h4">{`${name === undefined || name === "" ? schuetzenName : name}`}</Typography>
                </Grid>
                <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                <Tooltip title="Schütze löschen" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                    <Button
                        type="submit"
                        variant="contained"
                        color={deleteDisabled ? "inherit" : "error"}
                        disabled={!editDisabled || !hasLoaded}
                        onClick={handleDelete}
                        style={{ margin: 5 }}>
                        <DeleteIcon />
                    </Button></Tooltip>
                    <Tooltip title="Schütze bearbeiten" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                    <Button
                        type="submit"
                        variant="contained"
                        color="inherit"
                        disabled={checkDisabled()}
                        onClick={editClick}
                        style={{ margin: 5 }}>
                        {editDisabled ? <EditIcon /> : <SaveIcon />}
                    </Button></Tooltip>
                    <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                    <Button
                        type="submit"
                        variant="contained"
                        color="inherit"
                        onClick={goLastPage}
                        style={{ margin: 5 }}>
                        <ReturnIcon />
                    </Button></Tooltip>                    
                </Grid>
            </Grid>
            <Grid item container direction='column' style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "15px" }}>
                <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Name" required placeholder="z.B. Mustermann, Max" variant="standard" margin='dense' value={name} onChange={(event) => setName(event.target.value)} />
                <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Mannschaft" required placeholder='z.B. SG Musterstadt II' variant="standard" margin='dense' value={mannschaft} onChange={(event) => setMannschaft(event.target.value)} />
                <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Kontakt 1" placeholder='z.B. Tel. 01234 - 567890' variant="standard" margin='dense' value={kontakt1} onChange={(event) => setKontakt1(event.target.value)} />
                <TextField className={classes.TextField} disabled={editDisabled} id="standard-basic" label="Kontakt 2" placeholder='z.B. Mail max@mustermann.de' variant="standard" margin='dense' value={kontakt2} onChange={(event) => setKontakt2(event.target.value)} />
                <Grid style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <FormGroup aria-label="position" row>
                        <Tooltip title="Ein Mannschaftsführer wurde schon eingetragen!" arrow placement="right" disabled={true} disableInteractive={true} disableTouchListener={true} disableFocusListener={true} disableHoverListener={!checkMannschaftDisabled() || editDisabled === true}>
                            <FormControlLabel
                                control={<Checkbox checked={mannschaftsfuehrer === 1 || mannschaftsfuehrer === true ? true : false} onChange={(event) => { setMannschaftsfuehrer(event.target.checked); }} />}
                                label="Mannschaftsführer?"
                                labelPlacement="end"
                                disabled={checkMannschaftDisabled()}
                            />
                        </Tooltip>
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
    )
}