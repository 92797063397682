import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import keycloak from './keycloak'
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import App from './App';
import ResultsPage from "./components/ErgebnisseResultsPage"

function isEmbed() {
  if (window === window.parent)
    return false;
  else
    return true;
}

const theme = createTheme({
  palette: {
    background: {
      default: isEmbed() ? "#ffffff" : "#eaeaea"
    },
    primary: {
      light: "#5ef2ab",
      main: "#0fbe7c",
      dark: "#008d4f",
    },
    secondary: {
      light: "#5c5c5c",
      main: "#333333",
      dark: "#0c0c0c"
    },
    tertiary: {
      main: "#eaeaea"
    }
  }
})

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={(
          <ReactKeycloakProvider authClient={keycloak} initOptions={{ pkceMethod: 'S256' }}>
            <TokenUpdater />
              <App />
          </ReactKeycloakProvider>
          )} />
        <Route path="ergebnisse">
          <Route path=":id" element={(<ResultsPage />)}> </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  </Router>
);

function TokenUpdater() {
  const { keycloak, initialized } = useKeycloak();
  if (!keycloak.authenticated)
    return null;

  if (initialized)
    keycloak.onTokenExpired = updateToken;

  function updateToken() {
    keycloak.updateToken(10)
      .catch(() => { alert("Authentifizierungsfehler! Bitte neu anmelden!"); window.location.reload() });
    return;
  }
  return null;
}