// Example locale file for English, give this to your locale team to translate

const AG_GRID_LOCALE_DE = {
    // Set Filter
    selectAll: '(Alles selektieren)',
    selectAllSearchResults: '(Alle Suchresultate selektieren)',
    searchOoo: 'Suchen...',
    blanks: '(Leer)',
    noMatches: 'Keine Ergebnisse',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Gleich',
    notEqual: 'Ungleich',
    empty: 'Eins auswählen',

    // Number Filter
    lessThan: 'Kleiner als',
    greaterThan: 'Größer als',
    lessThanOrEqual: 'Kleiner als oder gleich',
    greaterThanOrEqual: 'Größer als oder gleich',
    inRange: 'Im Bereich',
    inRangeStart: 'von',
    inRangeEnd: 'bis',

    // Text Filter
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    startsWith: 'Fängt an mit',
    endsWith: 'Endet mit',

    // Date Filter
    dateFormatOoo: 'dd-mm-yyyy',

    // Filter Conditions
    andCondition: 'UND',
    orCondition: 'ODER',

    // Filter Buttons
    applyFilter: 'Bestätigen',
    resetFilter: 'Reset',
    clearFilter: 'Löschen',
    cancelFilter: 'Abbrechen',

    // Filter Titles
    textFilter: 'Textfilter',
    numberFilter: 'Nummernfilter',
    dateFilter: 'Datumfilter',
    setFilter: 'Filter setzen',

    // Side Bar
    columns: 'Spalten',
    filters: 'Filter',

    // columns tool panel
    pivotMode: 'Drehpunkt Modus',
    groups: 'Gruppen',
    rowGroupColumnsEmptyMessage: 'Hier ziehen um Zeilengruppen zu setzen',
    values: 'Werte',
    valueColumnsEmptyMessage: 'Hier ziehen um zu aggregieren',
    pivots: 'Spaltenname',
    pivotColumnsEmptyMessage: 'Hier ziehen um Spaltennamen zu setzen',

    // Header of the Default Group Column
    group: 'Gruppe',

    // Other
    loadingOoo: 'Lade...',
    noRowsToShow: 'Keine Zeilen zum Anzeigen',
    enabled: 'Aktiviert',

    // Menu
    pinColumn: 'Spalte anheften',
    pinLeft: 'Links anheften',
    pinRight: 'Rechts anheften',
    noPin: 'Nicht anheften',
    valueAggregation: 'Wert aggregieren',
    autosizeThiscolumn: 'Die Größe dieser Spalte automatisch anpassen',
    autosizeAllColumns: 'Die Größe aller Spalten automatisch anpassen',
    groupBy: 'Gruppieren nach',
    ungroupBy: 'Ungruppieren nach',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alle Erweitern',
    collapseAll: 'Alle zusammenklappen',
    copy: 'Kopieren',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Kopieren mit Header',
    paste: 'Einfügen',
    ctrlV: 'Ctrl+V',
    export: 'Exportieren',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Gefiltert',
    selectedRows: 'Selektiert',
    totalRows: 'Anzahl Zeilen',
    totalAndFilteredRows: 'Rows',
    more: 'Mehr',
    to: 'bis',
    of: 'von',
    page: 'Seite',
    nextPage: 'Nächste Seite',
    lastPage: 'Letzte Seite',
    firstPage: 'Erste Seite',
    previousPage: 'Vorherige Seite',

    // Pivoting
    pivotColumnGroupTotals: 'Insgesamt',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate:'indeterminate',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Column Group',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values',

    // ARIA LABEL FOR DIALOGS
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function'

}
export default AG_GRID_LOCALE_DE;