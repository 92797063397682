import "../app.css";
import "./aggrid.css";
import { Buffer } from 'buffer'
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CssBaseline from "@mui/material/CssBaseline";
import { AgGridReact } from 'ag-grid-react';
import AG_GRID_LOCALE_DE from './AG_Grid_Locale_De.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Typography, Grid, Container, Paper, Button, Tooltip } from '@mui/material';
import { useParams } from "react-router-dom";
import PDF from "./ErgebnissePDF";
import { apiaddress } from "../const"

const fileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
    background: {
        width: "100%",
        maxWidth: "1280px",
        minWidth: "750px",
        minHeight: "650px"
    },
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    datagrid: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));

export default function Main(props) {
    const params = useParams();
    const ID = params.id;
    const theme = useTheme();
    const [tabledata, setTableData] = useState([]);
    const classes = useStyles();

    const [gridApi, setGridApi] = useState([]);
    const [mannschaftData, setMannschaftData] = useState([]);
    const [tagesData, setTagesData] = useState([]);
    const [einzelData, setEinzelData] = useState([]);
    const [rundenkampfdata, setRundenkampfdata] = useState("");

    useEffect(() => {
        updateTable();
    }, [rundenkampfdata])

    useEffect(() => {
        updateRundenkampfdata();
    }, [])

    async function updateTable() {
        axios.get(`${apiaddress}/schuetzen/${ID}/ergebnisse`)
            .then(response => {
                let newTableData = [];
                response.data.forEach(value => {
                    newTableData.push(value)
                });
                if (newTableData.length > 0)
                    setTableData(newTableData);
            })
            .catch((err) => {
                if (err.response.status === 401)
                    alert("Authorisierungsfehler bei Datenabruf! Loggen Sie sich bitte erneut ein!")
                else if (err.response.status === 403)
                    alert("Authorisierungsfehler bei Datenabruf! Sie sind nicht berechtigt, diese Inhalte einzusehen!")
                else if (err.response.status !== 404)
                    alert("Fehler bei Datenabruf! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    function updateRundenkampfdata() {
        axios.get(`${apiaddress}/rundenkaempfedata/`, { params: { id: ID } })
            .then(response => {
                if (response.data) {
                    setRundenkampfdata(response.data);
                }
            })
            .catch((err) => {
                alert("Fehler bei Rundenkampf Datenabruf! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    function resizeGrid(params) {
        setTimeout(() => {
            let columnApi = params.columnApi;
            let allColumnIds = [];
            columnApi.getAllGridColumns().forEach((column) => {
                if (column.colId !== "summe" && !column.colId.startsWith("platz"))
                    allColumnIds.push(column.getId());
            });
            columnApi.autoSizeColumns(allColumnIds);
        }, 100);
    }

    function gridValueSchnittFormatter(params, emptySymbol = 1) {
        const value = params !== undefined && params !== null ? params.value !== undefined ? String(params.value) : String(params) : "";
        if (value === "NaN" || value === "" || value === undefined || value === null)
            return emptySymbol ? "─" : "";
        else
            return Number(value).toFixed(1).replace(".", ",");
    }

    function gridValueFormatter(params, emptySymbol = 1) {
        const value = params !== undefined && params !== null ? params.value !== undefined ? String(params.value) : String(params) : "";
        if (value === "NaN" || value === "" || value === undefined || value === null)
            return emptySymbol ? "─" : "";
        else
            return Number(value).toFixed(1).replace(".", ",").replace(",0", "");
    }

    function gridValueEWFormatter(params, emptySymbol = 1) {
        const value = params !== undefined && params !== null ? params.value !== undefined ? String(params.value) : String(params) : "";
        if (value === "NaN" || value === "" || value === undefined || value === null)
            return emptySymbol ? "─" : "";
        else {
            let prefix = "";
            if (params.data[`${params?.column?.colId}ew`] === true)
                prefix = "(EW) "
            return prefix + (Number(value).toFixed(1).replace(".", ",").replace(",0", ""));
        }
    }

    function isEmbed() {
        if (window == window.parent)
            return false;
        else
            return true;
    }

    function initGridApi(event) {
        let apiArray = gridApi;
        apiArray.push(event.api);
        setGridApi(apiArray);
    }

    useEffect(() => {
        setMannschaftData(() => {
            let newData = [];

            let columns = [];

            columns.push({ field: 'verein', headerName: 'Verein', minwidth: "100" });
            columns.push({
                field: 'wk1', headerName: 'WK1',
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk1);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 2) columns.push({
                field: 'wk2', headerName: 'WK2',
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk2);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minwidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 3) columns.push({
                field: 'wk3', headerName: 'WK3',
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk3);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minwidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 4) columns.push({
                field: 'wk4', headerName: 'WK4',
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk4);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minwidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 5) columns.push({
                field: 'wk5', headerName: 'WK5',
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk5);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minwidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 6) columns.push({
                field: 'wk6', headerName: 'WK6',
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk6);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minwidth: "50"
            });
            columns.push({
                field: 'summe', headerName: 'Summe', flex: 1,
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.summe);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "150"
            });
            columns.push({
                field: 'durchschnitt', headerName: 'Schnitt',
                valueFormatter: gridValueSchnittFormatter, type: 'rightAligned', minwidth: "150"
            });

            let rows = function () {
                const validData = tabledata.map((value, index, array) => {
                    if (value !== undefined) {
                        try {
                            const mannschaft = value.Mannschaft;
                            const id = value.Nummer;
                            const wk1 = String(value.WK1).replace(",", ".");
                            const wk2 = String(value.WK2).replace(",", ".");
                            const wk3 = String(value.WK3).replace(",", ".");
                            const wk4 = String(value.WK4).replace(",", ".");
                            const wk5 = String(value.WK5).replace(",", ".");
                            const wk6 = String(value.WK6).replace(",", ".");
                            const wk1ew = value.WK1EW === 1 || value.WK1EW === true ? true : false;
                            const wk2ew = value.WK2EW === 1 || value.WK2EW === true ? true : false;
                            const wk3ew = value.WK3EW === 1 || value.WK3EW === true ? true : false;
                            const wk4ew = value.WK4EW === 1 || value.WK4EW === true ? true : false;
                            const wk5ew = value.WK5EW === 1 || value.WK5EW === true ? true : false;
                            const wk6ew = value.WK6EW === 1 || value.WK6EW === true ? true : false;
                            if (mannschaft) {
                                return { 'nummer': id, 'mannschaft': mannschaft, "wk1": wk1, "wk1ew": wk1ew, "wk2": wk2, "wk2ew": wk2ew, "wk3": wk3, "wk3ew": wk3ew, "wk4": wk4, "wk4ew": wk4ew, "wk5": wk5, "wk5ew": wk5ew, "wk6": wk6, "wk6ew": wk6ew };
                            }
                        } catch (error) {
                        }
                    }
                });

                let mannschaften = {};
                let results = [];
                for (let i = 0; i < validData.length; i++) {
                    if (!mannschaften[validData[i].mannschaft])
                        mannschaften[validData[i].mannschaft] = [];
                    mannschaften[validData[i].mannschaft].push(validData[i]);
                }
                Object.keys(mannschaften).forEach(key => {
                    let mannschaft = mannschaften[key];
                    let result = {};
                    result.verein = mannschaft.mannschaft ? mannschaft.mannschaft : mannschaft[0].mannschaft;
                    result.nummer = mannschaft.nummer ? mannschaft.nummer : mannschaft[0].nummer;
                    result.wk1 = function () { let list = mannschaft.filter(schuetze => !schuetze.wk1ew && (!isNaN(schuetze.wk1) && schuetze.wk1 !== "")).sort((b, a) => a.wk1 - b.wk1); let results = 0; let counter = 0; while (list.length >= 1 && counter < 3) { results += Number(list.shift().wk1); counter++; } return counter > 0 ? results : null; }()
                    result.wk2 = rundenkampfdata.ZeigenBisWK >= 2 ? function () { let list = mannschaft.filter(schuetze => !schuetze.wk2ew && (!isNaN(schuetze.wk2) && schuetze.wk2 !== "")).sort((b, a) => a.wk2 - b.wk2); let results = 0; let counter = 0; while (list.length >= 1 && counter < 3) { results += Number(list.shift().wk2); counter++; } return counter > 0 ? results : null; }() : 0;
                    result.wk3 = rundenkampfdata.ZeigenBisWK >= 3 ? function () { let list = mannschaft.filter(schuetze => !schuetze.wk3ew && (!isNaN(schuetze.wk3) && schuetze.wk3 !== "")).sort((b, a) => a.wk3 - b.wk3); let results = 0; let counter = 0; while (list.length >= 1 && counter < 3) { results += Number(list.shift().wk3); counter++; } return counter > 0 ? results : null; }() : 0;
                    result.wk4 = rundenkampfdata.ZeigenBisWK >= 4 ? function () { let list = mannschaft.filter(schuetze => !schuetze.wk4ew && (!isNaN(schuetze.wk4) && schuetze.wk4 !== "")).sort((b, a) => a.wk4 - b.wk4); let results = 0; let counter = 0; while (list.length >= 1 && counter < 3) { results += Number(list.shift().wk4); counter++; } return counter > 0 ? results : null; }() : 0;
                    result.wk5 = rundenkampfdata.ZeigenBisWK >= 5 ? function () { let list = mannschaft.filter(schuetze => !schuetze.wk5ew && (!isNaN(schuetze.wk5) && schuetze.wk5 !== "")).sort((b, a) => a.wk5 - b.wk5); let results = 0; let counter = 0; while (list.length >= 1 && counter < 3) { results += Number(list.shift().wk5); counter++; } return counter > 0 ? results : null; }() : 0;
                    result.wk6 = rundenkampfdata.ZeigenBisWK >= 6 ? function () { let list = mannschaft.filter(schuetze => !schuetze.wk6ew && (!isNaN(schuetze.wk6) && schuetze.wk6 !== "")).sort((b, a) => a.wk6 - b.wk6); let results = 0; let counter = 0; while (list.length >= 1 && counter < 3) { results += Number(list.shift().wk6); counter++; } return counter > 0 ? results : null; }() : 0;
                    result.durchschnitt = function () {
                        let sum = 0;
                        let count = 0;
                        sum += result.wk1 !== null && result.wk1 !== "" && !isNaN(result.wk1) ? function () { count++; return result.wk1; }() : 0;
                        if (rundenkampfdata.ZeigenBisWK >= 2) { sum += result.wk2 !== null && result.wk2 !== "" && !isNaN(result.wk2) ? function () { count++; return result.wk2; }() : 0 };
                        if (rundenkampfdata.ZeigenBisWK >= 3) { sum += result.wk3 !== null && result.wk3 !== "" && !isNaN(result.wk3) ? function () { count++; return result.wk3; }() : 0 };
                        if (rundenkampfdata.ZeigenBisWK >= 4) { sum += result.wk4 !== null && result.wk4 !== "" && !isNaN(result.wk4) ? function () { count++; return result.wk4; }() : 0 };
                        if (rundenkampfdata.ZeigenBisWK >= 5) { sum += result.wk5 !== null && result.wk5 !== "" && !isNaN(result.wk5) ? function () { count++; return result.wk5; }() : 0 };
                        if (rundenkampfdata.ZeigenBisWK >= 6) { sum += result.wk6 !== null && result.wk6 !== "" && !isNaN(result.wk6) ? function () { count++; return result.wk6; }() : 0 };
                        return sum / count;
                    }();
                    result.summe = Number(result.wk1) + Number(result.wk2) + Number(result.wk3) + Number(result.wk4) + Number(result.wk5) + Number(result.wk6);
                    results.push(result);
                });
                results.sort((b, a) => a.summe - b.summe);
                return results;
            }();
            newData.push(columns);
            newData.push(rows);
            return newData;
        })

        setTagesData(() => {
            let newData = [];

            let columns = ([
                {
                    field: 'wettkampftag', headerName: 'Wettkampftag'
                    , cellRenderer: function (param) {
                        return (<Grid className="customCell">{param.value}</Grid>);
                    }, minwidth: "100"
                },
                {
                    field: 'platz1', headerName: 'Platz 1', flex: 1
                    ,
                    cellRenderer: function (param) {
                        const val = param.value === undefined || Object.keys(param.value).length > 0 ? param.value : undefined
                        const output = val ? [`${val.name} (${gridValueFormatter(val.result)})`, `${val.mannschaft}`] : undefined;
                        return output ? (<Grid className="customCell">{output[0]}<br></br>{output[1]}</Grid>) : <Grid className="customCell">Leer</Grid>;
                    }, minwidth: "100"
                },
                {
                    field: 'platz2', headerName: 'Platz 2', flex: 1
                    ,
                    cellRenderer: function (param) {
                        const val = param.value === undefined || Object.keys(param.value).length > 0 ? param.value : undefined
                        const output = val ? [`${val.name} (${gridValueFormatter(val.result)})`, `${val.mannschaft}`] : undefined;
                        return output ? (<Grid className="customCell">{output[0]}<br></br>{output[1]}</Grid>) : <Grid className="customCell">Leer</Grid>;
                    }, minwidth: "100"
                },
                {
                    field: 'platz3', headerName: 'Platz 3', flex: 1
                    ,
                    cellRenderer: function (param) {
                        const val = param.value === undefined || Object.keys(param.value).length > 0 ? param.value : undefined
                        const output = val ? [`${val.name} (${gridValueFormatter(val.result)})`, `${val.mannschaft}`] : undefined;
                        return output ? (<Grid className="customCell">{output[0]}<br></br>{output[1]}</Grid>) : <Grid className="customCell">Leer</Grid>;
                    }, minwidth: "100"
                },
            ]);
            let rows = function () {
                const validData = tabledata.map((value, index, array) => {
                    if (value !== undefined) {
                        try {
                            const num = value.Nummer;
                            const name = value.Name;
                            const mannschaft = value.Mannschaft;
                            const wk1 = String(value.WK1).replace(",", ".");
                            const wk2 = String(value.WK2).replace(",", ".");
                            const wk3 = String(value.WK3).replace(",", ".");
                            const wk4 = String(value.WK4).replace(",", ".");
                            const wk5 = String(value.WK5).replace(",", ".");
                            const wk6 = String(value.WK6).replace(",", ".");
                            if (name && mannschaft) {
                                return { "nummer": num, 'name': name, "mannschaft": mannschaft, "wk1": wk1, "wk2": wk2, "wk3": wk3, "wk4": wk4, "wk5": wk5, "wk6": wk6 };
                            }
                        } catch (error) {
                        }
                    }
                });
                if (validData.length === 0)
                    return undefined;

                const wk1data = validData.filter((schuetze) => !isNaN(schuetze.wk1) && schuetze.wk1 !== "").sort((b, a) => a.wk1 - b.wk1);
                let wk1results = [];
                for (let i = 0; i <= 2; i++) {
                    if (i < wk1data.length)
                        wk1results.push({ name: wk1data[i].name, result: wk1data[i].wk1, mannschaft: wk1data[i].mannschaft, nummer: wk1data[i].nummer })
                    else
                        wk1results.push("");
                }

                const wk2data = validData.filter((schuetze) => !isNaN(schuetze.wk2) && schuetze.wk2 !== "").sort((b, a) => a.wk2 - b.wk2);
                let wk2results = [];
                for (let i = 0; i <= 2; i++) {
                    if (i < wk2data.length)
                        wk2results.push({ name: wk2data[i].name, result: wk2data[i].wk2, mannschaft: wk2data[i].mannschaft, nummer: wk2data[i].nummer })
                    else
                        wk2results.push("");
                }

                const wk3data = validData.filter((schuetze) => !isNaN(schuetze.wk3) && schuetze.wk3 !== "").sort((b, a) => a.wk3 - b.wk3);
                let wk3results = [];
                for (let i = 0; i <= 2; i++) {
                    if (i < wk3data.length)
                        wk3results.push({ name: wk3data[i].name, result: wk3data[i].wk3, mannschaft: wk3data[i].mannschaft, nummer: wk3data[i].nummer })
                    else
                        wk3results.push("");
                }

                const wk4data = validData.filter((schuetze) => !isNaN(schuetze.wk4) && schuetze.wk4 !== "").sort((b, a) => a.wk4 - b.wk4);
                let wk4results = [];
                for (let i = 0; i <= 2; i++) {
                    if (i < wk4data.length)
                        wk4results.push({ name: wk4data[i].name, result: wk4data[i].wk4, mannschaft: wk4data[i].mannschaft, nummer: wk4data[i].nummer })
                    else
                        wk4results.push("");
                }

                const wk5data = validData.filter((schuetze) => !isNaN(schuetze.wk5) && schuetze.wk5 !== "").sort((b, a) => a.wk5 - b.wk5);
                let wk5results = [];
                for (let i = 0; i <= 2; i++) {
                    if (i < wk5data.length)
                        wk5results.push({ name: wk5data[i].name, result: wk5data[i].wk5, mannschaft: wk5data[i].mannschaft, nummer: wk5data[i].nummer })
                    else
                        wk5results.push("");
                }

                const wk6data = validData.filter((schuetze) => !isNaN(schuetze.wk6) && schuetze.wk6 !== "").sort((b, a) => a.wk6 - b.wk6);
                let wk6results = [];
                for (let i = 0; i <= 2; i++) {
                    if (i < wk6data.length)
                        wk6results.push({ name: wk6data[i].name, result: wk6data[i].wk6, mannschaft: wk6data[i].mannschaft, nummer: wk6data[i].nummer })
                    else
                        wk6results.push("");
                }

                let results = [];
                if (wk1results.some((value) => value !== ""))
                    results.push({ wettkampftag: "1. Wettkampftag", platz1: wk1results[0], platz2: wk1results[1], platz3: wk1results[2] })
                if (rundenkampfdata.ZeigenBisWK >= 2 && wk2results.some((value) => value !== ""))
                    results.push({ wettkampftag: "2. Wettkampftag", platz1: wk2results[0], platz2: wk2results[1], platz3: wk2results[2] })
                if (rundenkampfdata.ZeigenBisWK >= 3 && wk3results.some((value) => value !== ""))
                    results.push({ wettkampftag: "3. Wettkampftag", platz1: wk3results[0], platz2: wk3results[1], platz3: wk3results[2] })
                if (rundenkampfdata.ZeigenBisWK >= 4 && wk4results.some((value) => value !== ""))
                    results.push({ wettkampftag: "4. Wettkampftag", platz1: wk4results[0], platz2: wk4results[1], platz3: wk4results[2] })
                if (rundenkampfdata.ZeigenBisWK >= 5 && wk5results.some((value) => value !== ""))
                    results.push({ wettkampftag: "5. Wettkampftag", platz1: wk5results[0], platz2: wk5results[1], platz3: wk5results[2] })
                if (rundenkampfdata.ZeigenBisWK >= 6 && wk6results.some((value) => value !== ""))
                    results.push({ wettkampftag: "6. Wettkampftag", platz1: wk6results[0], platz2: wk6results[1], platz3: wk6results[2] })
                return results;
            }();
            newData.push(columns);
            newData.push(rows);
            return newData;
        })

        setEinzelData(() => {
            let newData = [];

            let columns = [];
            columns.push({ field: "nummer", hide: true });
            columns.push({ field: 'schuetze', headerName: 'Schütze', minWidth: "100" });
            columns.push({ field: 'verein', headerName: 'Verein', minWidth: "100" });

            columns.push({
                field: 'wk1', headerName: 'WK1',
                valueFormatter: gridValueEWFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk1);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 2) columns.push({
                field: 'wk2', headerName: 'WK2',
                valueFormatter: gridValueEWFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk2);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 3) columns.push({
                field: 'wk3', headerName: 'WK3',
                valueFormatter: gridValueEWFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk3);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 4) columns.push({
                field: 'wk4', headerName: 'WK4',
                valueFormatter: gridValueEWFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk4);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 5) columns.push({
                field: 'wk5', headerName: 'WK5',
                valueFormatter: gridValueEWFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk5);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            if (rundenkampfdata.ZeigenBisWK >= 6) columns.push({
                field: 'wk6', headerName: 'WK6',
                valueFormatter: gridValueEWFormatter,
                valueGetter: params => {
                    const value = String(params.data.wk6);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "50"
            });
            columns.push({
                field: 'summe', headerName: 'Summe', flex: 1,
                valueFormatter: gridValueFormatter,
                valueGetter: params => {
                    const value = String(params.data.summe);
                    return Number(value.replace(",", "."));
                }, type: 'rightAligned', minWidth: "150"
            });
            columns.push({
                field: 'durchschnitt', headerName: 'Schnitt',
                valueFormatter: gridValueSchnittFormatter, type: 'rightAligned', minwidth: "150"
            });

            let rows = function () {
                const validData = tabledata.map((value, index, array) => {
                    if (value !== undefined) {
                        try {
                            const name = value.Name;
                            const nummer = value.Nummer;
                            const mannschaft = value.Mannschaft;
                            let wk1 = function () { let val = String(value.WK1).replace(",", "."); return isNaN(val) ? null : Number(val) }();
                            let wk2 = rundenkampfdata.ZeigenBisWK >= 2 ? function () { let val = String(value.WK2).replace(",", "."); return isNaN(val) ? null : Number(val) }() : null;
                            let wk3 = rundenkampfdata.ZeigenBisWK >= 3 ? function () { let val = String(value.WK3).replace(",", "."); return isNaN(val) ? null : Number(val) }() : null;
                            let wk4 = rundenkampfdata.ZeigenBisWK >= 4 ? function () { let val = String(value.WK4).replace(",", "."); return isNaN(val) ? null : Number(val) }() : null;
                            let wk5 = rundenkampfdata.ZeigenBisWK >= 5 ? function () { let val = String(value.WK5).replace(",", "."); return isNaN(val) ? null : Number(val) }() : null;
                            let wk6 = rundenkampfdata.ZeigenBisWK >= 6 ? function () { let val = String(value.WK6).replace(",", "."); return isNaN(val) ? null : Number(val) }() : null;
                            const summe = wk1 + wk2 + wk3 + wk4 + wk5 + wk6;
                            const schnitt = function () {
                                let sum = 0;
                                let count = 0;
                                sum += wk1 !== null && wk1 !== "" && !isNaN(wk1) ? function () { count++; return wk1; }() : 0;
                                sum += wk2 !== null && wk2 !== "" && !isNaN(wk2) ? function () { count++; return wk2; }() : 0;
                                sum += wk3 !== null && wk3 !== "" && !isNaN(wk3) ? function () { count++; return wk3; }() : 0;
                                sum += wk4 !== null && wk4 !== "" && !isNaN(wk4) ? function () { count++; return wk4; }() : 0;
                                sum += wk5 !== null && wk5 !== "" && !isNaN(wk5) ? function () { count++; return wk5; }() : 0;
                                sum += wk6 !== null && wk6 !== "" && !isNaN(wk6) ? function () { count++; return wk6; }() : 0;
                                return sum / count;
                            }();
                            const wk1ew = value.WK1EW === 1 || value.WK1EW === true ? true : false;
                            const wk2ew = value.WK2EW === 1 || value.WK2EW === true ? true : false;
                            const wk3ew = value.WK3EW === 1 || value.WK3EW === true ? true : false;
                            const wk4ew = value.WK4EW === 1 || value.WK4EW === true ? true : false;
                            const wk5ew = value.WK5EW === 1 || value.WK5EW === true ? true : false;
                            const wk6ew = value.WK6EW === 1 || value.WK6EW === true ? true : false;
                            if (mannschaft) {
                                return { "schuetze": name, "durchschnitt": schnitt, "nummer": nummer, 'verein': mannschaft, "wk1": wk1, "wk1ew": wk1ew, "wk2": wk2, "wk2ew": wk2ew, "wk3": wk3, "wk3ew": wk3ew, "wk4": wk4, "wk4ew": wk4ew, "wk5": wk5, "wk5ew": wk5ew, "wk6": wk6, "wk6ew": wk6ew, "summe": summe };
                            }
                        } catch (error) {
                        }
                    }
                }).sort((b, a) => a.summe - b.summe)
                return validData;
            }();

            newData.push(columns);
            newData.push(rows);
            return newData;
        });
    }, [tabledata])

    function exportCSV() {
        if (gridApi.length === 0)
            return;
        const separator = ";";
        const wk = String(Math.min(getLastWk(), rundenkampfdata.ZeigenBisWK));
        let output = "";
        output += rundenkampfdata.Verband + "\n" + rundenkampfdata.Liga + separator + separator + "RK-Leiter: " + rundenkampfdata.Rundenkampfleiter + "\n" + rundenkampfdata.Disziplin + "\n" + `${wk}. Wettkampf` + "\n\n";

        gridApi.forEach((gridApi, index) => {
            let rows = gridApi.rowModel.rowsToDisplay;
            if (rows.length === 0 || rows[0].data?.length === 0) return;
            if (index === 1) {
                let newRow = [];
                const row = rows.filter((value) => value.data.wettkampftag.charAt(0) === wk)[0];
                newRow.push({ data: row.data.platz1 });
                newRow.push({ data: row.data.platz2 });
                newRow.push({ data: row.data.platz3 });
                rows = newRow;
            }
            const keys = index === 0 ? ["Platz", "Verein", "", "", "Summe"] : index === 1 ? ["Tageswertung (Einzel)"] : index === 2 ? ["Gesamtwertung (Einzel)"] : "";
            output += keys.map(key => String(key).charAt(0).toLocaleUpperCase() + key.slice(1)).join(separator);
            output += "\n";
            for (let i = 0; i < rows.length; i++) {
                let rowData = rows[i].data;
                let newRow = [];
                if (rowData) {
                    if (index === 0) {
                        newRow.push(i + 1);
                        newRow.push(rowData.verein)
                        newRow.push("");
                        newRow.push("");
                        newRow.push(gridValueFormatter(rowData["summe"], 0))
                    } else if (index === 1 && i < 3) {
                        newRow.push(Math.min(i + 1, 3))
                        newRow.push(rowData.name);
                        newRow.push("");
                        newRow.push(rowData.mannschaft);
                        let summe = gridValueFormatter(rowData.result, 0);
                        newRow.push(summe);
                    } else if (i < 3 && rowData.summe > 0) {
                        newRow.push(Math.min(i + 1, 3))
                        newRow.push(rowData.schuetze);
                        newRow.push("");
                        newRow.push(rowData.verein);
                        newRow.push(gridValueFormatter(rowData.summe, 0))
                    }
                }
                if (newRow.length > 0) {
                    output += newRow.join(separator);
                    output += "\n";
                }
            }
            output += "\n";
        })
        let fileName = `${rundenkampfdata.Name}-${rundenkampfdata.Verband}-${rundenkampfdata.Liga}-${rundenkampfdata.Disziplin}-Ergebnisse.csv`;
        fileName = String(fileName).trim().replaceAll(" ", "_");
        fileDownload(Buffer.from(output, "latin1"), fileName)
    }

    function getLastWk() {
        for (let i = 6; i >= 1; i--) {
            for (let tablei = 0; tablei < tabledata.length; tablei++) {
                const value = tabledata[tablei];
                const wkValue = value["WK" + String(i)];
                if (wkValue !== null && wkValue !== undefined && wkValue !== "") {
                    return i;
                }
            }
        }
        return "1";
    }

    function exportPDF() {
        if (mannschaftData && tagesData && einzelData) {
            const longCharLimitIndex = 35;
            const midCharLimitIndex = 20;
            const shortCharLimitIndex = 6;

            const newMannschaftData = mannschaftData[1].map((value) => {
                value.verein = value.verein.slice(0, longCharLimitIndex);
                value.wk1 = gridValueFormatter(value.wk1, 0).slice(0, shortCharLimitIndex);
                value.wk2 = gridValueFormatter(value.wk2, 0).slice(0, shortCharLimitIndex);
                value.wk3 = gridValueFormatter(value.wk3, 0).slice(0, shortCharLimitIndex);
                value.wk4 = gridValueFormatter(value.wk4, 0).slice(0, shortCharLimitIndex);
                value.wk5 = gridValueFormatter(value.wk5, 0).slice(0, shortCharLimitIndex);
                value.wk6 = gridValueFormatter(value.wk6, 0).slice(0, shortCharLimitIndex);
                value.summe = gridValueFormatter(value.summe, 0).slice(0, shortCharLimitIndex);
                value.durchschnitt = gridValueSchnittFormatter(value.durchschnitt, 0).slice(0, shortCharLimitIndex);
                return value;
            })
            const newTagesData = function () {
                let newRow = [];
                console.log(tagesData[1]);
                const row = tagesData[1].filter((value) => value.wettkampftag.charAt(0) === String(Math.min(getLastWk(), rundenkampfdata.ZeigenBisWK)))[0];
                if (row.platz1 && row.platz1 !== "") newRow.push({ result: gridValueFormatter(row.platz1.result).slice(0, shortCharLimitIndex), mannschaft: row.platz1.mannschaft.slice(0, midCharLimitIndex), name: row.platz1.name.slice(0, midCharLimitIndex) });
                if (row.platz2 && row.platz2 !== "") newRow.push({ result: gridValueFormatter(row.platz2.result).slice(0, shortCharLimitIndex), mannschaft: row.platz2.mannschaft.slice(0, midCharLimitIndex), name: row.platz2.name.slice(0, midCharLimitIndex) });
                if (row.platz3 && row.platz3 !== "") newRow.push({ result: gridValueFormatter(row.platz3.result).slice(0, shortCharLimitIndex), mannschaft: row.platz3.mannschaft.slice(0, midCharLimitIndex), name: row.platz3.name.slice(0, midCharLimitIndex) });
                return newRow;
            }();
            const newEinzelData = einzelData[1].map((value) => {
                value.schuetze = value.schuetze.slice(0, midCharLimitIndex);
                value.verein = value.verein.slice(0, midCharLimitIndex);
                value.wk1 = gridValueFormatter(value.wk1, 0).slice(0, shortCharLimitIndex);
                value.wk2 = gridValueFormatter(value.wk2, 0).slice(0, shortCharLimitIndex);
                value.wk3 = gridValueFormatter(value.wk3, 0).slice(0, shortCharLimitIndex);
                value.wk4 = gridValueFormatter(value.wk4, 0).slice(0, shortCharLimitIndex);
                value.wk5 = gridValueFormatter(value.wk5, 0).slice(0, shortCharLimitIndex);
                value.wk6 = gridValueFormatter(value.wk6, 0).slice(0, shortCharLimitIndex);
                value.summe = gridValueFormatter(value.summe, 0);
                value.durchschnitt = gridValueSchnittFormatter(value.durchschnitt, 0);
                return value;
            })
            PDF(rundenkampfdata, newMannschaftData, newTagesData, newEinzelData, String(Math.min(getLastWk(), rundenkampfdata.ZeigenBisWK)), rundenkampfdata.ZeigenBisWK)
        }
    }

    const exportButton = (
        <Tooltip title="Als CSV exportieren" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
            <Button
                type="submit"
                variant="contained"
                onClick={exportCSV}
                color="secondary"
                style={{  }}>
                <FileDownloadIcon />
                CSV
            </Button></Tooltip>
    );

    const pdfButton = (
        <Tooltip title="Als PDF exportieren" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
            <Button
                type="submit"
                variant="contained"
                onClick={exportPDF}
                color="secondary"
                style={{ marginLeft: 5 }}>
                <FileDownloadIcon />
                PDF
            </Button></Tooltip>
    );

    const content = (
        <>
            <CssBaseline />
            <Grid item container className={classes.background}>
                <Paper elevation={isEmbed() ? 0 : 2} style={{ width: "100%" }}>
                    <Grid item container direction="column" className={`${classes.maingrid}`} paddingLeft={isEmbed() ? 0 : 2} paddingTop={isEmbed() ? 0 : 2} paddingRight={isEmbed() ? 0 : 2}>
                        <Grid item marginBottom={1} >
                            <Grid item container justifyContent={"space-between"} style={{ height: "60px", paddingLeft: 17, paddingRight: 17, alignItems: "center", backgroundColor: theme.palette.tertiary.main }}>
                                <Typography variant="h4">Ergebnisse {rundenkampfdata.Name}</Typography>
                                <Grid>
                                    {exportButton}
                                    {pdfButton}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent={"space-between"} style={{ paddingLeft: 17, paddingRight: 17, paddingBottom: 5, alignItems: "center", backgroundColor: theme.palette.tertiary.main }}>
                                <Typography variant="h6">{rundenkampfdata.Disziplin}<br></br>{rundenkampfdata.Liga}<br></br>{rundenkampfdata.Rundenkampfleiter}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item marginBottom={1}>
                            <Grid item container style={{ height: "60px", paddingLeft: 17, alignItems: "center", backgroundColor: theme.palette.tertiary.main }}>
                                <Typography variant="h4">Mannschaftswertung</Typography>
                            </Grid>
                            <Grid item xs className="ag-theme-material" style={{ width: '100%' }} >
                                <AgGridReact localeText={AG_GRID_LOCALE_DE} defaultColDef={{ sortable: false, resizable: false, suppressColumnVirtualisation: true }} domLayout='autoHeight' enableCellTextSelection={true} ensureDomOrder={true} rowData={mannschaftData[1]} columnDefs={mannschaftData[0]} rowSelection="none" onGridReady={initGridApi} onModelUpdated={resizeGrid}
                                    onVirtualColumnsChanged={resizeGrid}>
                                </AgGridReact>
                            </Grid>
                        </Grid>
                        <Grid item marginBottom={2}>
                            <Grid item container style={{ height: "60px", paddingLeft: 17, alignItems: "center", backgroundColor: theme.palette.tertiary.main }}>
                                <Typography variant="h4">Tageswertung</Typography>
                            </Grid>
                            <Grid item xs className="ag-theme-material tageswertung" style={{ width: '100%' }} >
                                <AgGridReact localeText={AG_GRID_LOCALE_DE} defaultColDef={{ sortable: false, resizable: false, suppressColumnVirtualisation: true, autoHeight: true }} domLayout='autoHeight' enableCellTextSelection={true} ensureDomOrder={true} rowData={tagesData[1]} columnDefs={tagesData[0]} rowSelection="none" onGridReady={initGridApi} onModelUpdated={resizeGrid} onVirtualColumnsChanged={resizeGrid} onFirstDataRendered={resizeGrid}>
                                </AgGridReact>
                            </Grid>
                        </Grid>
                        <Grid item marginBottom={2}>
                            <Grid item container style={{ height: "60px", paddingLeft: 17, alignItems: "center", backgroundColor: theme.palette.tertiary.main }}>
                                <Typography variant="h4">Einzelwertung</Typography>
                            </Grid>
                            <Grid item xs className="ag-theme-material" style={{ width: '100%' }} >
                                <AgGridReact localeText={AG_GRID_LOCALE_DE} defaultColDef={{ sortable: false, resizable: false, suppressColumnVirtualisation: true }} domLayout='autoHeight' enableCellTextSelection={true} ensureDomOrder={true} rowData={einzelData[1]} columnDefs={einzelData[0]} rowSelection="none" onGridReady={initGridApi} onModelUpdated={resizeGrid} onVirtualColumnsChanged={resizeGrid}>
                                </AgGridReact>
                            </Grid>
                        </Grid>
                        <Typography textAlign={"center"} variant={"caption"}>Ergebnisse ermittelt und präsentiert von <a href="https://www.Rundenkampf.de/" ><b>Rundenkampf.de</b></a></Typography>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );

    const output = function () {
        if (isEmbed())
            return (<Grid>{content}</Grid>)
        else
            return (<Container>{content}</Container>)
    }();

    return output;
}