import "../app.css";
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import ReturnIcon from '@mui/icons-material/KeyboardReturn';
import AddIcon from '@mui/icons-material/Add';
import { useKeycloak } from '@react-keycloak/web'
import { AgGridReact } from 'ag-grid-react';
import AG_GRID_LOCALE_DE from './AG_Grid_Locale_De.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { formatDate } from './tools.js';
import { Breadcrumbs, Typography, Button, Grid, Tooltip } from '@mui/material';
import { apiaddress } from "../const"

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '80px',
        minWidth: "400px"
    },
    maingrid: {
        height: '100%',
        width: '100%'
    },
    datagrid: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));

export default function Main(props) {
    const theme = useTheme();
    const { keycloak, initialized } = useKeycloak();
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const [tabledata, setTableData] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const editID = props.editID;
    const goLastPage = props.goLastPage;
    const classes = useStyles();

    useEffect(() => {
        updateTable();
    }, [])

    async function updateTable() {
        await keycloak.updateToken(5)
        
        axios.get(`${apiaddress}/rundenkaempfe`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
            .then(response => {
                let newTableData = [];
                response.data.forEach(value => {
                    newTableData.push(value)
                });
                if (newTableData.length > 0)
                    setTableData(newTableData);
            })
            .catch((err) => {
                if (err.response.status === 401)
                    alert("Authorisierungsfehler bei Datenabruf! Loggen Sie sich bitte erneut ein!")
                else if (err.response.status === 403)
                    alert("Authorisierungsfehler bei Datenabruf! Sie sind nicht berechtigt, diese Inhalte einzusehen!")
                else if (err.response.status !== 404)
                    alert("Fehler bei Datenabruf! Bitte wenden Sie sich an einen Administrator!")
            })
    }

    function cellSelected(event) {
        editID(event.data.id, event.data.name)
    }

    function handleBackArrow(event) {
        setMainwindow("");
    }

    function convertDate(dateString) {
        if (dateString == new Date(dateString))
            return dateString;
        dateString = String(dateString).replace("_", ".");
        dateString = String(dateString).replace(" ", ".");
        let newDate = new Date(dateString.split(".")[0], Number(dateString.split(".")[1]) - 1, dateString.split(".")[2], dateString.split(".")[3], dateString.split(".")[4]);
        return newDate;
    }

    function handleReturn() {
        setMainwindow("");
    }

    function handleCreate() {
        setMainwindow("create");
    }

    function onGridReady(params) {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    }

    function resizeGrid() {
        const allColumnIds = [];
        if (gridApi && columnApi) {
            columnApi.getAllGridColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            columnApi.autoSizeColumns(allColumnIds)
            gridApi.sizeColumnsToFit();
        }
    }

    useEffect(() => {
        setColumns([
            { field: 'id', headerName: 'Nummer', hide: true },
            {
                suppressSizeToFit: true, minWidth: 180,
                field: 'erstelltam', headerName: 'Erstellt Am', filter: "agDateColumnFilter",
                filterParams: {
                    comparator: (selectedDate, dateString) => {
                        let convertedDate = convertDate(dateString).setHours(0, 0, 0, 0);
                        if (convertedDate > selectedDate)
                            return 1;
                        else if (convertedDate < selectedDate)
                            return -1;
                        return 0
                    }
                },
                valueFormatter: (value) => {
                    let formatted = formatDate(convertDate(value.value));
                    if (formatted)
                        return formatted.replace(/-/g, ".").slice(0, -3)
                    else
                        return value.value
                }
            },
            { field: 'name', headerName: 'Name', filter: "agTextColumnFilter", minWidth: 140 },
            { field: 'disziplin', headerName: 'Disziplin', filter: "agTextColumnFilter", minWidth: 150 },
            { field: 'verband', headerName: 'Verband', filter: "agTextColumnFilter", minWidth: 150 },
            { field: 'liga', headerName: 'Liga', filter: "agTextColumnFilter", minWidth: 140 },
            { field: 'leiter', headerName: 'Rundenkampfleiter', filter: "agTextColumnFilter", minWidth: 210 },
            { field: 'kontakt_1', headerName: 'Kontakt 1', filter: "agTextColumnFilter", minWidth: 150 },
            { field: 'kontakt_2', headerName: 'Kontakt 2', filter: "agTextColumnFilter", minWidth: 150, flex: 1, suppressSizeToFit: false }
        ]);

        setRows(tabledata.map((value, index, array) => {
            if (value !== undefined) {
                try {
                    const id = value.Rundenkampfnummer;
                    const name = value.Name;
                    const erstelltam = value.ErstelltAm;
                    const disziplin = value.Disziplin;
                    const verband = value.Verband;
                    const liga = value.Liga;
                    const leiter = value.Rundenkampfleiter;
                    const kontakt_1 = value.Kontakt_1;
                    const kontakt_2 = value.Kontakt_2;
                    if (id && name && erstelltam && disziplin && verband && liga && leiter) {
                        return { "id": id, 'name': name, "erstelltam": erstelltam, "disziplin": disziplin, "verband": verband, "liga": liga, "leiter": leiter, "kontakt_1": kontakt_1, "kontakt_2": kontakt_2 };
                    }
                } catch (error) {
                }
            }
        }));
    }, [tabledata])

    const createButton = keycloak?.tokenParsed?.resource_access["Rundenkampf.de"]?.roles?.some(r => ["Rundenkampfleiter", "Admin"].includes(r)) ?
        <Tooltip title="Neuen Rundenkampf erstellen" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
            <Button
                type="submit"
                variant="contained"
                color="inherit"
                onClick={handleCreate}
                style={{ margin: 5 }}>
                <AddIcon />
            </Button>
        </Tooltip> : "";

    return (
        <Grid xs item container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar} style={{ backgroundColor: theme.palette.primary.main }}>
                <Grid style={{ paddingLeft: 17 }} >
                    <Breadcrumbs>
                        <Button
                            className="breadcrumbs-button"
                            variant="text"
                            size='small'
                            color='secondary'
                            onClick={handleBackArrow}
                        >
                            Startseite
                        </Button>
                        <Typography ></Typography>

                    </Breadcrumbs>
                    <Typography variant="h4">Rundenkämpfe</Typography>
                </Grid>
                <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                    {createButton}
                    <Tooltip title="Zurück" arrow placement="top" disableInteractive={true} disableTouchListener={true} disableFocusListener={true} >
                        <Button
                            type="submit"
                            variant="contained"
                            color="inherit"
                            onClick={goLastPage}
                            style={{ margin: 5 }}>
                            <ReturnIcon />
                        </Button></Tooltip>
                </Grid>
            </Grid>
            <Grid item container xs>
                <div className="ag-theme-material" style={{ height: '100%', width: '100%', minHeight: "300px" }} >
                    <AgGridReact localeText={AG_GRID_LOCALE_DE} defaultColDef={{ sortable: true, resizable: true, suppressColumnVirtualisation: true, suppressSizeToFit: true }} rowData={rows} columnDefs={columns} rowSelection="single" pagination={true} paginationPageSize={50} onCellDoubleClicked={cellSelected} onGridReady={onGridReady} onFirstDataRendered={resizeGrid}>
                    </AgGridReact>
                </div>
            </Grid>
        </Grid>
    );
}