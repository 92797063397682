import { jsPDF } from "jspdf";
import { formatDate } from "./tools"


export default function Main(rundenkampfdata, mannschaftData, tagesData, einzelData, lastWK, wkLimit) {
    const date = formatDate(new Date());
    const doc = new jsPDF();

    const width = doc.getPageWidth();
    const height = doc.getPageHeight();
    const widthMargin = width * 0.08;
    const heightMargin = height * 0.03;

    const headerPosStart = heightMargin + 5;
    const tablePosStart = heightMargin + 49;
    let tablePosCur = tablePosStart;

    const colWidths = [widthMargin + 6, widthMargin + 45, widthMargin + 91, widthMargin + 101, widthMargin + 111, widthMargin + 121, widthMargin + 131, widthMargin + 141, widthMargin + 158, widthMargin + 175]

    const tableHeaderSize = 11;
    const tableNumberingSpacing = 5
    const tableDataSize = 9;
    const tableDataSpacing = 4;
    let dataCount = 0;
    let page = 1;
    let maxPageDataCount = 55

    doc.text(rundenkampfdata.Name, width / 2, headerPosStart, { align: "center" });
    doc.text(rundenkampfdata.Verband, width / 2, headerPosStart + 7, { align: "center" });
    doc.text(`${rundenkampfdata.Rundenkampfleiter}, Rundenkampfleiter ${rundenkampfdata.Liga}`, width / 2, headerPosStart + 14, { align: "center" });
    doc.text(rundenkampfdata.Disziplin, width / 2, headerPosStart + 25, { align: "center" })
    doc.text(rundenkampfdata.Liga, width / 2, headerPosStart + 32, { align: "center" })
    doc.line(widthMargin, headerPosStart + 36, width - widthMargin, headerPosStart + 36);
    //doc.rect(widthMargin, heightMargin, width - widthMargin * 2, height - heightMargin * 2);

    doc.saveGraphicsState();
    doc.setFontSize(8);
    doc.text(date, 5, height - 5);
    doc.text("Ergebnisse ermittelt und präsentiert von Rundenkampf.de", width / 2, height - 5, { align: "center" });
    doc.text(`Seite ${page}`, width - 5, height - 5, { align: "right" });
    doc.restoreGraphicsState();

    { // MWERTUNG
        doc.saveGraphicsState();
        doc.text("Mannschaftswertung", width / 2, tablePosCur, { align: "center" })
        doc.setFontSize(tableHeaderSize);
        doc.text("Verein", colWidths[0], tablePosCur + 5, { align: "left" })
        doc.text("WK1", colWidths[2], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 2) doc.text("WK2", colWidths[3], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 3) doc.text("WK3", colWidths[4], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 4) doc.text("WK4", colWidths[5], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 5) doc.text("WK5", colWidths[6], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 6) doc.text("WK6", colWidths[7], tablePosCur + 5, { align: "right" })
        doc.text("Summe", colWidths[8], tablePosCur + 5, { align: "right" })
        doc.text("Schnitt", colWidths[9], tablePosCur + 5, { align: "right" })

        doc.setFontSize(tableDataSize);
        let rowPos = 10;
        for (let i = 0; i < mannschaftData.length; i++) {
            const data = mannschaftData[i];
            doc.text(`${i + 1}`, colWidths[0] - tableNumberingSpacing, tablePosCur + rowPos, { align: "center" })
            doc.text(data.verein, colWidths[0], tablePosCur + rowPos, { align: "left" })
            doc.text(data.wk1, colWidths[2], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 2) doc.text(data.wk2, colWidths[3], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 3) doc.text(data.wk3, colWidths[4], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 4) doc.text(data.wk4, colWidths[5], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 5) doc.text(data.wk5, colWidths[6], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 6) doc.text(data.wk6, colWidths[7], tablePosCur + rowPos, { align: "right" })
            doc.text(data.summe, colWidths[8], tablePosCur + rowPos, { align: "right" })
            doc.text(data.durchschnitt, colWidths[9], tablePosCur + rowPos, { align: "right" })
            rowPos += tableDataSpacing;
            dataCount++;
            if (dataCount >= maxPageDataCount) {
                tablePosCur = headerPosStart;
                rowPos = 10;
                doc.addPage();
                page++;
                dataCount = 0;
                maxPageDataCount = 67
                doc.saveGraphicsState();
                doc.setFontSize(8);
                doc.text(formatDate(new Date()), 5, height - 5);
                doc.text("Ergebnisse ermittelt und präsentiert von Rundenkampf.de", width / 2, height - 5, { align: "center" });
                doc.text(`Seite ${page}`, width - 5, height - 5, { align: "right" });
                doc.restoreGraphicsState();
            }
        }
        tablePosCur = tablePosCur + rowPos + 10;
        doc.restoreGraphicsState();
    }

    if (dataCount + 5 >= maxPageDataCount) {
        dataCount = 0;
        maxPageDataCount = 67
        tablePosCur = headerPosStart;
        doc.addPage();
        page++;
        doc.saveGraphicsState();
        doc.setFontSize(8);
        doc.text(formatDate(new Date()), 5, height - 5);
        doc.text("Ergebnisse ermittelt und präsentiert von Rundenkampf.de", width / 2, height - 5, { align: "center" });
        doc.text(`Seite ${page}`, width - 5, height - 5, { align: "right" });
        doc.restoreGraphicsState();
    }

    { // TWERTUNG
        doc.saveGraphicsState();
        doc.text(`Tageswertung WK${lastWK}`, width / 2, tablePosCur, { align: "center" })
        doc.setFontSize(11);
        doc.text("Name", colWidths[0], tablePosCur + 5, { align: "left" })
        doc.text("Verein", colWidths[1], tablePosCur + 5, { align: "left" })
        doc.text("Ergebnis", colWidths[8], tablePosCur + 5, { align: "right" })
        doc.setFontSize(tableDataSize);
        maxPageDataCount -= 5;
        let rowPos = 10;
        for (let i = 0; i < tagesData.length; i++) {
            const data = tagesData[i];
            doc.text(`${i + 1}`, colWidths[0] - tableNumberingSpacing, tablePosCur + rowPos, { align: "center" })
            doc.text(data.name, colWidths[0], tablePosCur + rowPos, { align: "left" })
            doc.text(data.mannschaft, colWidths[1], tablePosCur + rowPos, { align: "left" })
            doc.text(data.result, colWidths[8], tablePosCur + rowPos, { align: "right" })
            rowPos += tableDataSpacing;
            dataCount++;
            if (dataCount >= maxPageDataCount) {
                tablePosCur = heightMargin;
                rowPos = 10;
                doc.addPage();
                page++;
                dataCount = 0;
                maxPageDataCount = 67
                doc.saveGraphicsState();
                doc.setFontSize(8);
                doc.text(formatDate(new Date()), 5, height - 5);
                doc.text("Ergebnisse ermittelt und präsentiert von Rundenkampf.de", width / 2, height - 5, { align: "center" });
                doc.text(`Seite ${page}`, width - 5, height - 5, { align: "right" });
                doc.restoreGraphicsState();
            }
        }
        tablePosCur = tablePosCur + rowPos + 10;
        doc.restoreGraphicsState();
    }

    if (dataCount + 5 >= maxPageDataCount) {
        dataCount = 0;
        maxPageDataCount = 67
        tablePosCur = headerPosStart;
        doc.addPage();
        page++;
        doc.saveGraphicsState();
        doc.setFontSize(8);
        doc.text(formatDate(new Date()), 5, height - 5);
        doc.text("Ergebnisse ermittelt und präsentiert von Rundenkampf.de", width / 2, height - 5, { align: "center" });
        doc.text(`Seite ${page}`, width - 5, height - 5, { align: "right" });
        doc.restoreGraphicsState();
    }

    { // EWERTUNG
        doc.saveGraphicsState();
        doc.text("Einzelwertung", width / 2, tablePosCur, { align: "center" })
        doc.setFontSize(11);
        doc.text("Name", colWidths[0], tablePosCur + 5, { align: "left" })
        doc.text("Verein", colWidths[1], tablePosCur + 5, { align: "left" })
        doc.text("WK1", colWidths[2], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 2) doc.text("WK2", colWidths[3], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 3) doc.text("WK3", colWidths[4], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 4) doc.text("WK4", colWidths[5], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 5) doc.text("WK5", colWidths[6], tablePosCur + 5, { align: "right" })
        if (wkLimit >= 6) doc.text("WK6", colWidths[7], tablePosCur + 5, { align: "right" })
        doc.text("Summe", colWidths[8], tablePosCur + 5, { align: "right" })
        doc.text("Schnitt", colWidths[9], tablePosCur + 5, { align: "right" })
        doc.setFontSize(tableDataSize);
        maxPageDataCount -= 5;
        let rowPos = 10;
        for (let i = 0; i < einzelData.length; i++) {
            const data = einzelData[i];
            doc.text(`${i + 1}`, colWidths[0] - tableNumberingSpacing, tablePosCur + rowPos, { align: "center" })
            doc.text(data.schuetze, colWidths[0], tablePosCur + rowPos, { align: "left" })
            doc.text(data.verein, colWidths[1], tablePosCur + rowPos, { align: "left" })
            doc.text(data.wk1, colWidths[2], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 2) doc.text(data.wk2, colWidths[3], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 3) doc.text(data.wk3, colWidths[4], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 4) doc.text(data.wk4, colWidths[5], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 5) doc.text(data.wk5, colWidths[6], tablePosCur + rowPos, { align: "right" })
            if (wkLimit >= 6) doc.text(data.wk6, colWidths[7], tablePosCur + rowPos, { align: "right" })
            doc.text(data.summe, colWidths[8], tablePosCur + rowPos, { align: "right" })
            doc.text(data.durchschnitt, colWidths[9], tablePosCur + rowPos, { align: "right" })
            rowPos += tableDataSpacing;
            dataCount++;
            if (dataCount >= maxPageDataCount) {
                tablePosCur = headerPosStart;
                rowPos = 10;
                maxPageDataCount = 67
                doc.addPage();
                page++;
                dataCount = 0;

                doc.restoreGraphicsState();
                doc.text("Einzelwertung", width / 2, tablePosCur, { align: "center" })
                doc.saveGraphicsState();
                doc.setFontSize(11);
                doc.text("Name", colWidths[0], tablePosCur + 5, { align: "left" })
                doc.text("Verein", colWidths[1], tablePosCur + 5, { align: "left" })
                doc.text("WK1", colWidths[2], tablePosCur + 5, { align: "right" })
                if (wkLimit >= 2) doc.text("WK2", colWidths[3], tablePosCur + 5, { align: "right" })
                if (wkLimit >= 3) doc.text("WK3", colWidths[4], tablePosCur + 5, { align: "right" })
                if (wkLimit >= 4) doc.text("WK4", colWidths[5], tablePosCur + 5, { align: "right" })
                if (wkLimit >= 5) doc.text("WK5", colWidths[6], tablePosCur + 5, { align: "right" })
                if (wkLimit >= 6) doc.text("WK6", colWidths[7], tablePosCur + 5, { align: "right" })
                doc.text("Summe", colWidths[8], tablePosCur + 5, { align: "right" })
                doc.text("Schnitt", colWidths[9], tablePosCur + 5, { align: "right" })
                doc.setFontSize(tableDataSize);
                doc.saveGraphicsState();
                doc.setFontSize(8);
                doc.text(formatDate(new Date()), 5, height - 5);
                doc.text("Ergebnisse ermittelt und präsentiert von Rundenkampf.de", width / 2, height - 5, { align: "center" });
                doc.text(`Seite ${page}`, width - 5, height - 5, { align: "right" });
                doc.restoreGraphicsState();
            }
        }
        tablePosCur = tablePosCur + rowPos + 10;
        doc.restoreGraphicsState();
    }

    let fileName = `${rundenkampfdata.Name}-${rundenkampfdata.Verband}-${rundenkampfdata.Liga}-${rundenkampfdata.Disziplin}-Ergebnisse.pdf`;
    fileName = String(fileName).trim().replaceAll(" ", "_");
    doc.save(fileName);

}