import "./app.css";
import { makeStyles, useTheme } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { CardActionArea, CardContent, CardMedia, Paper, Stack, Container, Card, Grid, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web'
import { MainWindowContext } from "./MainWindowContext.js";
import TablePage from "./components/tablePage";
import SchuetzenTablePage from "./components/SchuetzenTablePage";
import WritePage from "./components/writePage";
import WriteSchuetzePage from "./components/WriteSchuetzePage";
import SchuetzenEditPage from "./components/SchuetzenEditPage"
import ErgebnisseTablePage from "./components/ErgebnisseTablePage";
import Header from "./components/Header";
import Footer from './components/Footer';
import FooterPage from "./components/Footerpages"
import EditPage from './components/EditPage';
import CreateIcon from "./img/create.png";
import SearchIcon from "./img/search.png";
import Schuetze1 from "./img/schuetze-1.jpg";
import Schuetze2 from "./img/schuetze-2.jpg";
import Schuetze3 from "./img/schuetze-3.jpg";
import CssBaseline from "@mui/material/CssBaseline";
import ImageGallery from 'react-image-gallery';

const useStyles = makeStyles(() => ({
    background: {
        height: "100vh",
        width: "100%",
        maxWidth: "1280px",
        minWidth: "750px",
        padding: "20px"
    },
    header: {
        height: "13ßpx",
        minWidth: "300px",
        backgroundColor: 'white',
        zIndex: "0"
    },
    footer: {
        backgroundColor: 'white',
    },
    toolbar: {
        height: '80px',
        minWidth: "400px"
    }
}));

function App(props) {
    const classes = useStyles();
    const [mainWindow, setMainWindow] = useState("");
    const [_mainWindow, _setMainWindow] = useState("");
    const [prevPage, setPrevPage] = useState([]);
    const { keycloak, initialized } = useKeycloak();
    const [selectedID, setSelectedID] = useState("");
    const [selectedName, setSelectedName] = useState("");
    const [selectedSchuetzenID, setSelectedSchuetzenID] = useState("");
    const [selectedSchuetzenName, setSelectedSchuetzenName] = useState("");
    const [loadedSchuetzen, setLoadedSchuetzen] = useState({});
    const [footerOpen, setFooterOpen] = useState(undefined);

    let main;
    if (mainWindow === "search")
        main =
            (
                <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                    <TablePage editID={editID} goLastPage={goLastPage} />
                </MainWindowContext.Provider>
            );
    else if (mainWindow === "create")
        main =
            (
                <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                    <WritePage goLastPage={goLastPage} />
                </MainWindowContext.Provider>
            );
    else if (mainWindow === "edit")
        main =
            (
                <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                    <EditPage id={selectedID} goLastPage={goLastPage} />
                </MainWindowContext.Provider>
            );
    else if (mainWindow === "editSchuetzen")
        main =
            (
                <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                    <SchuetzenEditPage id={selectedID} schuetzenID={selectedSchuetzenID} schuetzenName={selectedSchuetzenName} loadedSchuetzen={loadedSchuetzen} name={selectedName} goLastPage={goLastPage} />
                </MainWindowContext.Provider>
            );
    else if (mainWindow === "searchSchuetzen")
        main =
            (<MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                <SchuetzenTablePage id={selectedID} name={selectedName} editID={editID} editSchuetzenID={editSchuetzenID} addSchuetze={addSchuetze} goLastPage={goLastPage} />
            </MainWindowContext.Provider>

            )
    else if (mainWindow === "createSchuetze")
        main =
            (
                <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                    <WriteSchuetzePage id={selectedID} name={selectedName} loadedSchuetzen={loadedSchuetzen} goLastPage={goLastPage} />
                </MainWindowContext.Provider>
            );
    else if (mainWindow === "ergebnisse")
        main =
            (
                <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                    <ErgebnisseTablePage id={selectedID} name={selectedName} editID={editID} goLastPage={goLastPage} />
                </MainWindowContext.Provider>
            )
    else
        main = (
            <MainWindowContext.Provider value={[_mainWindow, _setMainWindow]}>
                <Dashboard />
            </MainWindowContext.Provider>
        );

    useEffect(() => {
        if (_mainWindow === undefined)
            return;
        changeMainWindow(_mainWindow, true);
    }, [_mainWindow])

    function goLastPage() {
        let newPrevPage = prevPage;
        if (newPrevPage.length > 0) {
            const lastPage = newPrevPage.pop();

            if (lastPage.page) {
                setSelectedID(lastPage.selectedID);
                setSelectedName(lastPage.selectedName);
                setSelectedSchuetzenID(lastPage.selectedSchuetzenID);
                setSelectedSchuetzenName(lastPage.selectedSchuetzenName);
                changeMainWindow(lastPage.page, false);
            }
            else
                changeMainWindow(lastPage, false);
            setPrevPage(newPrevPage);
        }
    }

    function changeMainWindow(page, addPrev) {
        if (addPrev === true) {

            let newPrevPage = prevPage;
            newPrevPage.push({ "page": mainWindow, "selectedID": selectedID, "selectedName": selectedName, "selectedSchuetzenID": selectedSchuetzenID, "selectedSchuetzenName": selectedSchuetzenName });
            setPrevPage(newPrevPage);
        }
        const newPage = page;
        setMainWindow(newPage);
        _setMainWindow(undefined);
    }

    function editID(ID, name) {
        setSelectedID(ID);
        setSelectedName(name);
        _setMainWindow("edit");
    }

    function editSchuetzenID(ID, name, schuetzen) {
        setSelectedSchuetzenID(ID);
        setSelectedSchuetzenName(name);
        setLoadedSchuetzen(schuetzen)
        _setMainWindow("editSchuetzen");
    }

    function addSchuetze(schuetzen) {
        setLoadedSchuetzen(schuetzen);
        _setMainWindow("createSchuetze");
    }

    function changeOpenFooter(event) {
        setFooterOpen(event?.target?.textContent);
    }

    function closeFooter() {
        setFooterOpen(undefined);
    }

    return (
        main === null ? "" :
            <Container>
                <CssBaseline />
                <Grid container className={classes.background}>
                    <Paper elevation={2} style={{ width: "100%" }}>
                        <Grid item container direction="column" style={{ height: "100%", width: "100%" }}>
                            < Header keycloak={keycloak} />
                            <Grid item container xs>
                                <div style={{ position: "relative", width: "100%" }}>
                                    <FooterPage open={footerOpen} onClosed={closeFooter} />
                                    <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                        {main}
                                    </div>
                                </div>
                            </Grid>
                            <Footer onClick={changeOpenFooter} />
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
    );
}

function Dashboard() {
    const classes = useStyles();
    const { keycloak, initialized } = useKeycloak();
    const [mainWindow, setMainWindow] = useContext(MainWindowContext);

    function handleCreateClick(event) {
        setMainWindow("create");;
    }

    function handleSearchClick(event) {
        setMainWindow("search");
    }

    const createCard = keycloak?.tokenParsed?.resource_access["Rundenkampf.de"]?.roles?.some(r => ["Rundenkampfleiter", "Admin"].includes(r)) ?
        <Card id="anlegen" variant='outlined' style={{ borderColor: "transparent", borderTopLeftRadius: "5%", borderTopRightRadius: "5%", width: "160px" }} >
            <CardActionArea onClick={handleCreateClick} >
                <CardMedia
                    component="img"
                    image={CreateIcon}
                    alt="Suchen"
                    style={{ padding: "10px" }}
                />
                <CardContent>
                    <Typography color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>Rundenkampf</Typography>
                    <Typography color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>erstellen</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
        :
        <Grid item container xs style={{ overflow: "auto", fontSize: "22px" }}><div style={{ padding: 10, textAlign: "center", display: "grid" }}>
            <p><b>Herzlich Willkommen auf der Seite unseres Rundenkampf-Wettkampfplaners!</b></p>
            
            <p>Der Wettkampfplaner wurde vom Schützenkreis Kaiserslautern-Donnersberg e.V. zur Abwicklung von Rundenkämpfen entwickelt. Die Lösung soll das Zusammenführen und Aktualisieren der eingesammelten Rundenkampfdaten vereinfachen und die Ergebnisse sodann einheitlich präsentieren</p>

            <p>Der Funktionsumfang umfasst das Anlegen von Rundenkämpfen und Schützen (m/w/d) sowie die Eingabe der Ergebnisse pro Schütze und Wettkampftag. Am Ende steht die Ergebnispräsentation in Form einer kleinen Webseite zur Verfügung, auf die via URL verlinkt oder diese via iFrame in die eigene Homepage eingebunden werden kann. Außerdem können die Ergebnisseiten als PDF oder CSV exportiert werden.</p>

            <p>Wir weisen darauf hin, dass der Funktionsumfang auf die oben genannten Funktionen eingeschränkt ist und darüber hinaus keine Weiterentwicklung geplant ist. Vor diesem Hintergrund ist die Lösung nicht mit kommerziellen Angeboten vergleichbar und soll auch nicht in Konkurrenz zu diesen stehen. Darüber hinaus ist die Zielgruppe der Applikation rein auf die Rundenkampfleiter vom Schützenkreis Kaiserslautern-Donnersberg e.V. zugeschnitten. Sollte über diese Zielgruppe hinaus jemand Fragen oder Anmerkungen zu unserem Tool haben, kann dafür bitte das unten verlinkte Kontaktformular verwendet werden.</p>
        </div>
        </Grid>;

    const searchCard = keycloak?.authenticated ? <Card id="suchen" variant='outlined' style={{ borderColor: "transparent", borderTopLeftRadius: "5%", borderTopRightRadius: "5%", width: "160px" }} >
        <CardActionArea onClick={handleSearchClick}>
            <CardMedia
                component="img"
                image={SearchIcon}
                alt="Suchen"
                style={{ padding: "10px" }}
            />
            <CardContent>
                <Typography variant='h6' color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>{`Rundenkampf`}</Typography>
                <Typography variant='h6' color="textSecondary" style={{ fontWeight: "700", textAlign: "center", fontSize: "18px" }}>{`suchen`}</Typography>
            </CardContent>
        </CardActionArea>
    </Card> : "";

    const images = [
        { original: Schuetze1 },
        { original: Schuetze2 },
        { original: Schuetze3 }
    ];

    return (
        <Grid item container direction='column'>
            <Grid item container >
                <ImageGallery className={classes.image} items={images} showNav={false} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} useWindowKeyDown={false} slideInterval={10000} slideDuration={1000} autoPlay={true}></ImageGallery>
            </Grid>
            <Grid item style={{ margin: "25px" }}>
                <Stack direction={"row"} spacing={5} >
                    {createCard}
                    {searchCard}
                </Stack>
            </Grid>
        </Grid >
    );
}

export default App;